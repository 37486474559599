h1,
.h1 {
  margin-bottom: 20px;
  font-size: 65px;
  line-height: 1.29;
  font-weight: 700;

  @include media-breakpoint-down(lg) {
    margin-bottom: 15px;
    font-size: 48px;
    line-height: 1.2;
  }

  @include media-breakpoint-down(sm) {
    font-size: 34px;
  }
}

h2,
.h2 {
}

h3,
.h3 {
  margin-bottom: 29px;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;

  @include media-breakpoint-down(lg) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 8px;
    font-size: 28px;
  }
}

h4,
.h4 {
}

h5,
.h5 {
}

h6,
.h6 {
}

p,
.p {
  margin-bottom: 12px;
  font-size: 21px;
  line-height: 1.72;

  @include media-breakpoint-down(lg) {
    font-size: 18px;
    line-height: 1.56;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.font-weight-medium {
  font-weight: 500;
}

ul {

  li {
  }
}

ol {

  li {
  }
}

table {
}

tr {
}

th {
}

td {
}

a {
}

input {

  &:focus {
    outline: none;
  }
}

.nowrap {
  white-space: nowrap;
}

textarea {

  &:focus {
    outline: none;
  }
}

select {
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input[type='submit'] {
  border: 0;
  cursor: pointer;
}

img {
}
