.picture {

  @include media-breakpoint-down(lg) {
    zoom: .7;
  }

  @include media-breakpoint-down(sm) {
    zoom: .6;
  }

  &__main {
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-top: 0 !important;
      margin-left: 0 !important;
    }

    &:before {
      position: absolute;
      z-index: -1;
      display: block;
      content: '';

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &--picture1 {

      &:before {
        top: -176px;
        right: -392px;
        width: 562px;
        height: 297px;
        background: url(../img/picture1-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 587px;
        left: 223px;
        opacity: 0;
      }
    }

    &--picture2 {

      &:before {
        top: -85px;
        left: -395px;
        width: 555px;
        height: 248px;
        background: url(../img/picture2-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 56px;
        right: -119.5px;
        opacity: 0;
      }
    }

    &--picture3 {
      margin-top: 240px;

      &:before {
        top: -85px;
        left: 120px;
        width: 675px;
        height: 218px;
        background: url(../img/picture3-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 0;
        right: 0;
        opacity: 0;

        &:nth-child(2) {
          margin-right: 152px;
        }

        &:nth-child(3) {
          margin-right: 237px;
        }

        &:nth-child(4) {
          margin-right: 322px;
        }
      }
    }

    &--picture4-5 {
      position: relative;

      @include media-breakpoint-down(sm) {
        left: 150px;
        margin-bottom: 150px !important;
      }

      .picture__part {
        top: 17px;
        left: 230px;
        animation: tail 2s infinite;

        @include media-breakpoint-down(sm) {
          left: 180px;
        }
      }

      @keyframes tail {

        0% {
          transform: rotate(0);
          transform-origin: left bottom;
        }

        50% {
          transform: rotate(10deg);
          transform-origin: left bottom;
        }

        100% {
          transform: rotate(0);
          transform-origin: left bottom;
        }
      }
    }

    &--picture4 {
      margin-top: 220px;
      margin-right: -40px;

      &:before {
        top: -540px;
        left: -540px;
        width: 731px;
        height: 633px;
        background: url(../img/picture4-arrow.svg) no-repeat;
        background-size: contain;

        @include media-breakpoint-down(lg) {
          left: -460px;
        }
      }

      .picture__part {
        top: 0;

        &--1 {
          top: -103.5px;
          left: 51px;
        }

        &--2 {
          top: -145.5px;
          left: 136px;
        }

        &--3 {
          top: -102px;
          left: 278px;
        }
      }
    }

    &--picture5 {
      margin-top: 210px;
      margin-bottom: 34px;

      &:before {
        z-index: -2;
        top: 170px;
        left: 140px;
        width: 587px;
        height: 102px;
        background: url(../img/picture5-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__bg {
        position: absolute;
        z-index: -1;
        top: 50px;
        left: 38px;
        width: 263px;
        height: 262px;
        background: url(../img/picture5-bg.svg) no-repeat center;
        background-size: contain;
      }

      .picture__part {
        z-index: -1;
        top: 190px;
        left: 172px;

        svg {
          animation: sun 15s infinite linear;
        }
      }

      @keyframes sun {

        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &--picture6 {
      margin-top: 191px;

      &:before {
        z-index: -2;
        top: -500px;
        left: -640px;
        width: 827px;
        height: 639px;
        background: url(../img/picture6-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: 20px;
          left: -30px;
        }

        &--2 {
          top: 42px;
          left: 224px;
        }

        &--3 {
          top: 167px;
          left: -20px;
        }

        &--4 {
          top: -41px;
          left: 168px;
        }

        &--5 {
          top: 146px;
          left: 167px;
          transform: translate(30px, 30px);
        }
      }
    }

    &--picture7 {
      margin-top: 388px;

      .picture__part {
        top: -16px;
        left: -31px;

        svg path {

          &:nth-child(1) {
            animation: lights 2s steps(1) infinite;
            animation-delay: 1s;
          }

          &:nth-child(2) {
            opacity: 0;
            animation: lights 2s steps(1) infinite;
          }

          &:nth-child(3) {
            opacity: 0;
            animation: lights 2s steps(1) infinite;
          }

          &:nth-child(4) {
            animation: lights 2s steps(1) infinite;
            animation-delay: 1s;
          }

          &:nth-child(5) {
            animation: lights 2s steps(1) infinite;
            animation-delay: 1s;
          }
        }
      }

      @keyframes lights {

        0% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }
      }
    }

    &--picture8 {
      margin-top: 104px;

      &:before {
        top: -470px;
        left: -480px;
        width: 627px;
        height: 654px;
        background: url(../img/picture7-arrow.svg) no-repeat;
        background-size: contain;
      }

      & > svg {
        position: relative;
        z-index: 1;
        animation: superman 1.5s infinite ease-in-out;
      }

      .picture__main-bg {
        position: absolute;
        top: -17px;
        left: -60px;
      }

      .picture__part {
        top: 85px;
        left: -42px;
        animation: clouds 1s infinite ease-out;
      }

      @keyframes superman {

        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(8px);
        }

        100% {
          transform: translateY(0);
        }
      }

      @keyframes clouds {

        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(3px);
        }

        100% {
          transform: translateY(0);
        }
      }
    }

    &--picture9 {
      margin-top: 53px;

      &:before {
        top: -150px;
        left: 150px;
        width: 574px;
        height: 214px;
        background: url(../img/picture8-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: -146px;
        left: -10px;
      }
    }

    &--picture10 {
      margin-top: 197px;

      &:before {
        top: -30px;
        left: -270px;
        width: 384px;
        height: 168px;
        background: url(../img/picture9-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: -44px;
          left: 206px;
          animation: flag 4s infinite;
        }

        &--2 {
          top: 148px;
          left: 97px;
          animation: leg 2s infinite;
        }

        &--3 {
          top: -65px;
          left: 20px;
          animation: clouds 3s infinite ease-out;
        }
      }

      @keyframes leg {

        0% {
          transform: rotate(0);
          transform-origin: top center;
        }

        50% {
          transform: rotate(10deg);
          transform-origin: top center;
        }

        100% {
          transform: rotate(0);
          transform-origin: top center;
        }
      }

      @keyframes flag {

        0% {
          transform: rotate(0);
          transform-origin: left bottom;
        }

        50% {
          transform: rotate(5deg);
          transform-origin: left bottom;
        }

        100% {
          transform: rotate(0);
          transform-origin: left bottom;
        }
      }
    }

    &--picture11 {
      margin-top: 15px;

      &:before {
        top: -280px;
        left: 190px;
        width: 586px;
        height: 391px;
        background: url(../img/picture10-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 261px;
        left: 256px;
        opacity: 0;
      }
    }

    &--picture12 {
      margin-top: 90px;

      &:before {
        top: -220px;
        left: -330px;
        width: 508px;
        height: 307px;
        background: url(../img/picture11-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: 20px;
          left: 80px;
          animation: jaw 3s infinite;
        }

        &--2 {
          top: -45px;
          left: -3px;
          opacity: 0;
          animation: blood 3s infinite;
        }
      }

      @keyframes jaw {

        0% {
          transform: rotate(0) translateY(0);
          transform-origin: 70% 80%;
        }

        50% {
          transform: rotate(5deg) translateY(-5px);
          transform-origin: 70% 80%;
        }

        100% {
          transform: rotate(0) translateY(0);
          transform-origin: 80% 80%;
        }
      }

      @keyframes blood {

        0% {
          opacity: 0;
        }

        50% {
          opacity: 1;
        }

        60% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }
    }

    &--picture13 {
      margin-top: 240px;

      &:before {
        top: 10px;
        left: 200px;
        width: 461px;
        height: 73px;
        background: url(../img/picture12-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: 13px;
          left: 30px;
        }

        &--2 {
          top: 13px;
          left: 74px;
        }

        &--3 {
          top: 14px;
          left: 123px;
        }

        &--4 {
          top: 10px;
          left: 150px;
        }
      }
    }

    &--picture14 {
      margin-top: 70px;

      &:before {
        z-index: -2;
        top: -320px;
        left: -240px;
        width: 626px;
        height: 412px;
        background: url(../img/picture13-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__bg {
        position: absolute;
        z-index: -2;
        top: -46px;
        left: -5px;
        width: 636px;
        height: 629px;
        background: url(../img/picture14-bg.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: 52px;
          left: 410px;
          animation: tabletLight 3s infinite linear;
        }

        &--2 {
          z-index: -1;
          top: 256px;
          left: 10px;
          animation: ship1 3s infinite linear;
          animation-delay: 2s;
        }

        &--3 {
          z-index: -1;
          top: 228px;
          left: 115px;
          animation: ship2 4s infinite linear;
        }
      }

      @keyframes tabletLight {

        0% {
          opacity: 0;
        }

        40% {
          opacity: 1;
        }

        60% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }

      @keyframes ship1 {

        0% {
          transform: rotate(0);
          transform-origin: center bottom;
        }

        50% {
          transform: rotate(10deg);
          transform-origin: center bottom;
        }

        100% {
          transform: rotate(0);
          transform-origin: center bottom;
        }
      }

      @keyframes ship2 {

        0% {
          transform: rotate(0);
          transform-origin: center bottom;
        }

        50% {
          transform: rotate(10deg);
          transform-origin: center bottom;
        }

        100% {
          transform: rotate(0);
          transform-origin: center bottom;
        }
      }
    }

    &--picture15 {

      &:before {
        z-index: -3;
        top: -90px;
        left: 140px;
        width: 556px;
        height: 188px;
        background: url(../img/picture14-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 46px;
        left: 42px;
      }
    }

    &--picture16 {
      margin-top: 240px;

      &:before {
        top: -90px;
        left: -400px;
        width: 497px;
        height: 152px;
        background: url(../img/picture15-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        z-index: -1;
        top: 0;
        left: 64px;
        opacity: 0;
      }
    }

    &--picture17 {
      margin-top: 240px;

      &:before {
        top: -190px;
        left: 180px;
        width: 530px;
        height: 304px;
        background: url(../img/picture16-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {

        &--1 {
          top: -17px;
          left: -55px;
          opacity: 0;
          animation: stars 1s steps(1) infinite;
        }

        &--2 {
          top: -42px;
          left: -26px;
          opacity: 0;
          animation: stars 1s steps(1) infinite;
          animation-delay: .5s;
        }

        @keyframes stars {

          0% {
            opacity: 0;
          }

          50% {
            opacity: 1;
          }
        }
      }
    }

    &--picture18 {
      margin-top: 157px;

      &:before {
        top: -30px;
        left: -340px;
        width: 474px;
        height: 134px;
        background: url(../img/picture17-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 161px;
        left: 25px;
      }
    }

    &--picture19 {

      &:before {
        top: -270px;
        left: 250px;
        width: 535px;
        height: 328px;
        background: url(../img/picture18-arrow.svg) no-repeat;
        background-size: contain;
      }

      .picture__part {
        top: 107px;
        left: 98px;
        animation: shine 2s steps(1) infinite;
      }

      @keyframes shine {

        0% {
          opacity: 0;
          transform: rotate(0deg);
        }

        25% {
          opacity: 1;
          transform: rotate(0deg);
        }

        50% {
          opacity: 0;
          transform: rotate(-7deg);
        }

        75% {
          opacity: 1;
          transform: rotate(-7deg);
        }
      }
    }
  }

  &__part {
    position: absolute;
    transform: translate3d(0, 0, 0);
  }
}
