.footer {
  padding: 60px 0 30px;

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__top-left {
  }

  &__logo {
    margin-bottom: 12px;
    display: block;
  }

  &__logo-img {
    max-width: 100%;
  }

  &__sitemap {
    margin-left: 216px;
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
      margin-left: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-flow: row wrap;
    }
  }

  &__sitemap-section {

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
      width: 50%;
    }
  }

  &__sitemap-header {
    margin-bottom: 23px;
    font-size: 15px;
    line-height: 1.33;
    font-weight: 700;
    color: $black;
  }

  &__sitemap-links {
  }

  &__top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__phone {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    color: #1f2430;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 1;
    }
  }

  &__text {
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    line-height: 1.43;
    color: $dark;
  }

  &__bottom {
    margin-top: 60px;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #bdcfe4;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }

  &__bottom-left {
  }

  &__copyright {
    font-size: 12px;
    line-height: 1.33;
    color: #868698;
  }

  &__nti {
    padding: 8px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Chevin Pro', 'Arial', sans-serif;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 3px;

    &:hover {
      opacity: .8;
      color: #fff;
    }

    span {
      color: #bce1ff;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 13px;
    }
  }

  &__bottom-right {
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
      flex-direction: column;
    }
  }

  &__bottom-text {
    font-size: 12px;
    line-height: 1.33;
    color: #51596c;

    & + & {
      margin-left: 30px;

      @include media-breakpoint-down(sm) {
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }
}
