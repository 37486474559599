@import 'bootstrap';

@import 'mixins';

/* Plugins */
@import 'plugins/plugins-rewrite';

/* Global styles */
@import 'global/global-fonts';

@import 'global/global-typo';

@import 'global/global-scaffolding';

/* Blocks */

@import './src/blocks/front/front';

@import './src/blocks/logo/logo';

@import './src/blocks/item/item';

@import './src/blocks/animation/animation';

@import './src/blocks/footer/footer';

@import './src/blocks/content/content';

@import './src/blocks/socials/socials';

@import './src/blocks/picture/picture';

@import './src/blocks/wrapper/wrapper';

@import './src/blocks/share/share';
