@font-face {
  font-family: 'ALS Hauss';
  src: url('../fonts/ALSHauss-Regular.woff2') format('woff2'),
    url('../fonts/ALSHauss-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../fonts/ALSHauss-Bold.woff2') format('woff2'),
    url('../fonts/ALSHauss-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
