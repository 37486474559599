/* Стандартный transition для hover-анимаций */

@mixin transition {
  transition: $transition-base;
}

/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
@mixin placeholder($color) {

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}
