@charset "UTF-8";
/* Custom variables */
/* Project variables */
/* Imports */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2572f9;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #3f3f55;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "ALS Hauss", "Arial", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(30, 30, 47, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "ALS Hauss", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 10px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2572f9;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #064ecc;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid rgba(30, 30, 47, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 10px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 5px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1152px; } }

.container-fluid, .container-xl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1152px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #a1c2fc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 2; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2572f9;
  border-color: #2572f9; }
  .btn-primary:hover {
    color: #fff;
    background-color: #075cf1;
    border-color: #0657e5; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #075cf1;
    border-color: #0657e5;
    box-shadow: 0 0 0 0.2rem rgba(70, 135, 250, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2572f9;
    border-color: #2572f9; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0657e5;
    border-color: #0652d8; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 135, 250, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3f3f55;
  border-color: #3f3f55; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2f2f3f;
    border-color: #292938; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2f2f3f;
    border-color: #292938;
    box-shadow: 0 0 0 0.2rem rgba(92, 92, 111, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3f3f55;
    border-color: #3f3f55; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #292938;
    border-color: #242430; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 92, 111, 0.5); }

.btn-outline-primary {
  color: #2572f9;
  border-color: #2572f9; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2572f9;
    border-color: #2572f9; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2572f9;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2572f9;
    border-color: #2572f9; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #3f3f55;
  border-color: #3f3f55; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3f3f55;
    border-color: #3f3f55; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 63, 85, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3f3f55;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3f3f55;
    border-color: #3f3f55; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 63, 85, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2572f9;
  text-decoration: none; }
  .btn-link:hover {
    color: #064ecc;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 2;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2572f9;
    background-color: #2572f9; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a1c2fc; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d3e2fe;
    border-color: #d3e2fe; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2572f9;
  background-color: #2572f9; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(37, 114, 249, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(37, 114, 249, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(37, 114, 249, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(37, 114, 249, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #a1c2fc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a1c2fc;
    box-shadow: 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(37, 114, 249, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2572f9;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d3e2fe; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2572f9;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d3e2fe; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2572f9;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d3e2fe; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2572f9 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0657e5 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3f3f55 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #292938 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2572f9 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3f3f55 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(30, 30, 47, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(30, 30, 47, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(30, 30, 47, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 2.5px !important; }

.mt-1,
.my-1 {
  margin-top: 2.5px !important; }

.mr-1,
.mx-1 {
  margin-right: 2.5px !important; }

.mb-1,
.my-1 {
  margin-bottom: 2.5px !important; }

.ml-1,
.mx-1 {
  margin-left: 2.5px !important; }

.m-2 {
  margin: 5px !important; }

.mt-2,
.my-2 {
  margin-top: 5px !important; }

.mr-2,
.mx-2 {
  margin-right: 5px !important; }

.mb-2,
.my-2 {
  margin-bottom: 5px !important; }

.ml-2,
.mx-2 {
  margin-left: 5px !important; }

.m-3 {
  margin: 10px !important; }

.mt-3,
.my-3 {
  margin-top: 10px !important; }

.mr-3,
.mx-3 {
  margin-right: 10px !important; }

.mb-3,
.my-3 {
  margin-bottom: 10px !important; }

.ml-3,
.mx-3 {
  margin-left: 10px !important; }

.m-4 {
  margin: 15px !important; }

.mt-4,
.my-4 {
  margin-top: 15px !important; }

.mr-4,
.mx-4 {
  margin-right: 15px !important; }

.mb-4,
.my-4 {
  margin-bottom: 15px !important; }

.ml-4,
.mx-4 {
  margin-left: 15px !important; }

.m-5 {
  margin: 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5,
.mx-5 {
  margin-left: 5px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35,
.my-35 {
  margin-top: 35px !important; }

.mr-35,
.mx-35 {
  margin-right: 35px !important; }

.mb-35,
.my-35 {
  margin-bottom: 35px !important; }

.ml-35,
.mx-35 {
  margin-left: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40,
.my-40 {
  margin-top: 40px !important; }

.mr-40,
.mx-40 {
  margin-right: 40px !important; }

.mb-40,
.my-40 {
  margin-bottom: 40px !important; }

.ml-40,
.mx-40 {
  margin-left: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45,
.my-45 {
  margin-top: 45px !important; }

.mr-45,
.mx-45 {
  margin-right: 45px !important; }

.mb-45,
.my-45 {
  margin-bottom: 45px !important; }

.ml-45,
.mx-45 {
  margin-left: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.m-55 {
  margin: 55px !important; }

.mt-55,
.my-55 {
  margin-top: 55px !important; }

.mr-55,
.mx-55 {
  margin-right: 55px !important; }

.mb-55,
.my-55 {
  margin-bottom: 55px !important; }

.ml-55,
.mx-55 {
  margin-left: 55px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60,
.my-60 {
  margin-top: 60px !important; }

.mr-60,
.mx-60 {
  margin-right: 60px !important; }

.mb-60,
.my-60 {
  margin-bottom: 60px !important; }

.ml-60,
.mx-60 {
  margin-left: 60px !important; }

.m-65 {
  margin: 65px !important; }

.mt-65,
.my-65 {
  margin-top: 65px !important; }

.mr-65,
.mx-65 {
  margin-right: 65px !important; }

.mb-65,
.my-65 {
  margin-bottom: 65px !important; }

.ml-65,
.mx-65 {
  margin-left: 65px !important; }

.m-70 {
  margin: 70px !important; }

.mt-70,
.my-70 {
  margin-top: 70px !important; }

.mr-70,
.mx-70 {
  margin-right: 70px !important; }

.mb-70,
.my-70 {
  margin-bottom: 70px !important; }

.ml-70,
.mx-70 {
  margin-left: 70px !important; }

.m-75 {
  margin: 75px !important; }

.mt-75,
.my-75 {
  margin-top: 75px !important; }

.mr-75,
.mx-75 {
  margin-right: 75px !important; }

.mb-75,
.my-75 {
  margin-bottom: 75px !important; }

.ml-75,
.mx-75 {
  margin-left: 75px !important; }

.m-80 {
  margin: 80px !important; }

.mt-80,
.my-80 {
  margin-top: 80px !important; }

.mr-80,
.mx-80 {
  margin-right: 80px !important; }

.mb-80,
.my-80 {
  margin-bottom: 80px !important; }

.ml-80,
.mx-80 {
  margin-left: 80px !important; }

.m-85 {
  margin: 85px !important; }

.mt-85,
.my-85 {
  margin-top: 85px !important; }

.mr-85,
.mx-85 {
  margin-right: 85px !important; }

.mb-85,
.my-85 {
  margin-bottom: 85px !important; }

.ml-85,
.mx-85 {
  margin-left: 85px !important; }

.m-90 {
  margin: 90px !important; }

.mt-90,
.my-90 {
  margin-top: 90px !important; }

.mr-90,
.mx-90 {
  margin-right: 90px !important; }

.mb-90,
.my-90 {
  margin-bottom: 90px !important; }

.ml-90,
.mx-90 {
  margin-left: 90px !important; }

.m-95 {
  margin: 95px !important; }

.mt-95,
.my-95 {
  margin-top: 95px !important; }

.mr-95,
.mx-95 {
  margin-right: 95px !important; }

.mb-95,
.my-95 {
  margin-bottom: 95px !important; }

.ml-95,
.mx-95 {
  margin-left: 95px !important; }

.m-100 {
  margin: 100px !important; }

.mt-100,
.my-100 {
  margin-top: 100px !important; }

.mr-100,
.mx-100 {
  margin-right: 100px !important; }

.mb-100,
.my-100 {
  margin-bottom: 100px !important; }

.ml-100,
.mx-100 {
  margin-left: 100px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 2.5px !important; }

.pt-1,
.py-1 {
  padding-top: 2.5px !important; }

.pr-1,
.px-1 {
  padding-right: 2.5px !important; }

.pb-1,
.py-1 {
  padding-bottom: 2.5px !important; }

.pl-1,
.px-1 {
  padding-left: 2.5px !important; }

.p-2 {
  padding: 5px !important; }

.pt-2,
.py-2 {
  padding-top: 5px !important; }

.pr-2,
.px-2 {
  padding-right: 5px !important; }

.pb-2,
.py-2 {
  padding-bottom: 5px !important; }

.pl-2,
.px-2 {
  padding-left: 5px !important; }

.p-3 {
  padding: 10px !important; }

.pt-3,
.py-3 {
  padding-top: 10px !important; }

.pr-3,
.px-3 {
  padding-right: 10px !important; }

.pb-3,
.py-3 {
  padding-bottom: 10px !important; }

.pl-3,
.px-3 {
  padding-left: 10px !important; }

.p-4 {
  padding: 15px !important; }

.pt-4,
.py-4 {
  padding-top: 15px !important; }

.pr-4,
.px-4 {
  padding-right: 15px !important; }

.pb-4,
.py-4 {
  padding-bottom: 15px !important; }

.pl-4,
.px-4 {
  padding-left: 15px !important; }

.p-5 {
  padding: 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35,
.py-35 {
  padding-top: 35px !important; }

.pr-35,
.px-35 {
  padding-right: 35px !important; }

.pb-35,
.py-35 {
  padding-bottom: 35px !important; }

.pl-35,
.px-35 {
  padding-left: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40,
.py-40 {
  padding-top: 40px !important; }

.pr-40,
.px-40 {
  padding-right: 40px !important; }

.pb-40,
.py-40 {
  padding-bottom: 40px !important; }

.pl-40,
.px-40 {
  padding-left: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45,
.py-45 {
  padding-top: 45px !important; }

.pr-45,
.px-45 {
  padding-right: 45px !important; }

.pb-45,
.py-45 {
  padding-bottom: 45px !important; }

.pl-45,
.px-45 {
  padding-left: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.p-55 {
  padding: 55px !important; }

.pt-55,
.py-55 {
  padding-top: 55px !important; }

.pr-55,
.px-55 {
  padding-right: 55px !important; }

.pb-55,
.py-55 {
  padding-bottom: 55px !important; }

.pl-55,
.px-55 {
  padding-left: 55px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60,
.py-60 {
  padding-top: 60px !important; }

.pr-60,
.px-60 {
  padding-right: 60px !important; }

.pb-60,
.py-60 {
  padding-bottom: 60px !important; }

.pl-60,
.px-60 {
  padding-left: 60px !important; }

.p-65 {
  padding: 65px !important; }

.pt-65,
.py-65 {
  padding-top: 65px !important; }

.pr-65,
.px-65 {
  padding-right: 65px !important; }

.pb-65,
.py-65 {
  padding-bottom: 65px !important; }

.pl-65,
.px-65 {
  padding-left: 65px !important; }

.p-70 {
  padding: 70px !important; }

.pt-70,
.py-70 {
  padding-top: 70px !important; }

.pr-70,
.px-70 {
  padding-right: 70px !important; }

.pb-70,
.py-70 {
  padding-bottom: 70px !important; }

.pl-70,
.px-70 {
  padding-left: 70px !important; }

.p-75 {
  padding: 75px !important; }

.pt-75,
.py-75 {
  padding-top: 75px !important; }

.pr-75,
.px-75 {
  padding-right: 75px !important; }

.pb-75,
.py-75 {
  padding-bottom: 75px !important; }

.pl-75,
.px-75 {
  padding-left: 75px !important; }

.p-80 {
  padding: 80px !important; }

.pt-80,
.py-80 {
  padding-top: 80px !important; }

.pr-80,
.px-80 {
  padding-right: 80px !important; }

.pb-80,
.py-80 {
  padding-bottom: 80px !important; }

.pl-80,
.px-80 {
  padding-left: 80px !important; }

.p-85 {
  padding: 85px !important; }

.pt-85,
.py-85 {
  padding-top: 85px !important; }

.pr-85,
.px-85 {
  padding-right: 85px !important; }

.pb-85,
.py-85 {
  padding-bottom: 85px !important; }

.pl-85,
.px-85 {
  padding-left: 85px !important; }

.p-90 {
  padding: 90px !important; }

.pt-90,
.py-90 {
  padding-top: 90px !important; }

.pr-90,
.px-90 {
  padding-right: 90px !important; }

.pb-90,
.py-90 {
  padding-bottom: 90px !important; }

.pl-90,
.px-90 {
  padding-left: 90px !important; }

.p-95 {
  padding: 95px !important; }

.pt-95,
.py-95 {
  padding-top: 95px !important; }

.pr-95,
.px-95 {
  padding-right: 95px !important; }

.pb-95,
.py-95 {
  padding-bottom: 95px !important; }

.pl-95,
.px-95 {
  padding-left: 95px !important; }

.p-100 {
  padding: 100px !important; }

.pt-100,
.py-100 {
  padding-top: 100px !important; }

.pr-100,
.px-100 {
  padding-right: 100px !important; }

.pb-100,
.py-100 {
  padding-bottom: 100px !important; }

.pl-100,
.px-100 {
  padding-left: 100px !important; }

.m-n1 {
  margin: -2.5px !important; }

.mt-n1,
.my-n1 {
  margin-top: -2.5px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -2.5px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -2.5px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -2.5px !important; }

.m-n2 {
  margin: -5px !important; }

.mt-n2,
.my-n2 {
  margin-top: -5px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -5px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -5px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -5px !important; }

.m-n3 {
  margin: -10px !important; }

.mt-n3,
.my-n3 {
  margin-top: -10px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -10px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -10px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -10px !important; }

.m-n4 {
  margin: -15px !important; }

.mt-n4,
.my-n4 {
  margin-top: -15px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -15px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -15px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -15px !important; }

.m-n5 {
  margin: -5px !important; }

.mt-n5,
.my-n5 {
  margin-top: -5px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5px !important; }

.m-n10 {
  margin: -5px !important; }

.mt-n10,
.my-n10 {
  margin-top: -5px !important; }

.mr-n10,
.mx-n10 {
  margin-right: -5px !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -5px !important; }

.ml-n10,
.mx-n10 {
  margin-left: -5px !important; }

.m-n15 {
  margin: -5px !important; }

.mt-n15,
.my-n15 {
  margin-top: -5px !important; }

.mr-n15,
.mx-n15 {
  margin-right: -5px !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -5px !important; }

.ml-n15,
.mx-n15 {
  margin-left: -5px !important; }

.m-n20 {
  margin: -5px !important; }

.mt-n20,
.my-n20 {
  margin-top: -5px !important; }

.mr-n20,
.mx-n20 {
  margin-right: -5px !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -5px !important; }

.ml-n20,
.mx-n20 {
  margin-left: -5px !important; }

.m-n25 {
  margin: -5px !important; }

.mt-n25,
.my-n25 {
  margin-top: -5px !important; }

.mr-n25,
.mx-n25 {
  margin-right: -5px !important; }

.mb-n25,
.my-n25 {
  margin-bottom: -5px !important; }

.ml-n25,
.mx-n25 {
  margin-left: -5px !important; }

.m-n30 {
  margin: -5px !important; }

.mt-n30,
.my-n30 {
  margin-top: -5px !important; }

.mr-n30,
.mx-n30 {
  margin-right: -5px !important; }

.mb-n30,
.my-n30 {
  margin-bottom: -5px !important; }

.ml-n30,
.mx-n30 {
  margin-left: -5px !important; }

.m-n35 {
  margin: -5px !important; }

.mt-n35,
.my-n35 {
  margin-top: -5px !important; }

.mr-n35,
.mx-n35 {
  margin-right: -5px !important; }

.mb-n35,
.my-n35 {
  margin-bottom: -5px !important; }

.ml-n35,
.mx-n35 {
  margin-left: -5px !important; }

.m-n40 {
  margin: -5px !important; }

.mt-n40,
.my-n40 {
  margin-top: -5px !important; }

.mr-n40,
.mx-n40 {
  margin-right: -5px !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -5px !important; }

.ml-n40,
.mx-n40 {
  margin-left: -5px !important; }

.m-n45 {
  margin: -5px !important; }

.mt-n45,
.my-n45 {
  margin-top: -5px !important; }

.mr-n45,
.mx-n45 {
  margin-right: -5px !important; }

.mb-n45,
.my-n45 {
  margin-bottom: -5px !important; }

.ml-n45,
.mx-n45 {
  margin-left: -5px !important; }

.m-n50 {
  margin: -5px !important; }

.mt-n50,
.my-n50 {
  margin-top: -5px !important; }

.mr-n50,
.mx-n50 {
  margin-right: -5px !important; }

.mb-n50,
.my-n50 {
  margin-bottom: -5px !important; }

.ml-n50,
.mx-n50 {
  margin-left: -5px !important; }

.m-n55 {
  margin: -5px !important; }

.mt-n55,
.my-n55 {
  margin-top: -5px !important; }

.mr-n55,
.mx-n55 {
  margin-right: -5px !important; }

.mb-n55,
.my-n55 {
  margin-bottom: -5px !important; }

.ml-n55,
.mx-n55 {
  margin-left: -5px !important; }

.m-n60 {
  margin: -5px !important; }

.mt-n60,
.my-n60 {
  margin-top: -5px !important; }

.mr-n60,
.mx-n60 {
  margin-right: -5px !important; }

.mb-n60,
.my-n60 {
  margin-bottom: -5px !important; }

.ml-n60,
.mx-n60 {
  margin-left: -5px !important; }

.m-n65 {
  margin: -5px !important; }

.mt-n65,
.my-n65 {
  margin-top: -5px !important; }

.mr-n65,
.mx-n65 {
  margin-right: -5px !important; }

.mb-n65,
.my-n65 {
  margin-bottom: -5px !important; }

.ml-n65,
.mx-n65 {
  margin-left: -5px !important; }

.m-n70 {
  margin: -5px !important; }

.mt-n70,
.my-n70 {
  margin-top: -5px !important; }

.mr-n70,
.mx-n70 {
  margin-right: -5px !important; }

.mb-n70,
.my-n70 {
  margin-bottom: -5px !important; }

.ml-n70,
.mx-n70 {
  margin-left: -5px !important; }

.m-n75 {
  margin: -5px !important; }

.mt-n75,
.my-n75 {
  margin-top: -5px !important; }

.mr-n75,
.mx-n75 {
  margin-right: -5px !important; }

.mb-n75,
.my-n75 {
  margin-bottom: -5px !important; }

.ml-n75,
.mx-n75 {
  margin-left: -5px !important; }

.m-n80 {
  margin: -5px !important; }

.mt-n80,
.my-n80 {
  margin-top: -5px !important; }

.mr-n80,
.mx-n80 {
  margin-right: -5px !important; }

.mb-n80,
.my-n80 {
  margin-bottom: -5px !important; }

.ml-n80,
.mx-n80 {
  margin-left: -5px !important; }

.m-n85 {
  margin: -5px !important; }

.mt-n85,
.my-n85 {
  margin-top: -5px !important; }

.mr-n85,
.mx-n85 {
  margin-right: -5px !important; }

.mb-n85,
.my-n85 {
  margin-bottom: -5px !important; }

.ml-n85,
.mx-n85 {
  margin-left: -5px !important; }

.m-n90 {
  margin: -5px !important; }

.mt-n90,
.my-n90 {
  margin-top: -5px !important; }

.mr-n90,
.mx-n90 {
  margin-right: -5px !important; }

.mb-n90,
.my-n90 {
  margin-bottom: -5px !important; }

.ml-n90,
.mx-n90 {
  margin-left: -5px !important; }

.m-n95 {
  margin: -5px !important; }

.mt-n95,
.my-n95 {
  margin-top: -5px !important; }

.mr-n95,
.mx-n95 {
  margin-right: -5px !important; }

.mb-n95,
.my-n95 {
  margin-bottom: -5px !important; }

.ml-n95,
.mx-n95 {
  margin-left: -5px !important; }

.m-n100 {
  margin: -5px !important; }

.mt-n100,
.my-n100 {
  margin-top: -5px !important; }

.mr-n100,
.mx-n100 {
  margin-right: -5px !important; }

.mb-n100,
.my-n100 {
  margin-bottom: -5px !important; }

.ml-n100,
.mx-n100 {
  margin-left: -5px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 2.5px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 2.5px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 2.5px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 2.5px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 2.5px !important; }
  .m-sm-2 {
    margin: 5px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 5px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 5px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 5px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 5px !important; }
  .m-sm-3 {
    margin: 10px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 10px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 10px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 10px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 10px !important; }
  .m-sm-4 {
    margin: 15px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 15px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 15px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 15px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 15px !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important; }
  .m-sm-55 {
    margin: 55px !important; }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 55px !important; }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 55px !important; }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important; }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 55px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 60px !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 60px !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 60px !important; }
  .m-sm-65 {
    margin: 65px !important; }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 65px !important; }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 65px !important; }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important; }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 65px !important; }
  .m-sm-70 {
    margin: 70px !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 70px !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 70px !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 70px !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 70px !important; }
  .m-sm-75 {
    margin: 75px !important; }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 75px !important; }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 75px !important; }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75px !important; }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 75px !important; }
  .m-sm-80 {
    margin: 80px !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 80px !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 80px !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 80px !important; }
  .m-sm-85 {
    margin: 85px !important; }
  .mt-sm-85,
  .my-sm-85 {
    margin-top: 85px !important; }
  .mr-sm-85,
  .mx-sm-85 {
    margin-right: 85px !important; }
  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important; }
  .ml-sm-85,
  .mx-sm-85 {
    margin-left: 85px !important; }
  .m-sm-90 {
    margin: 90px !important; }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 90px !important; }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 90px !important; }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important; }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 90px !important; }
  .m-sm-95 {
    margin: 95px !important; }
  .mt-sm-95,
  .my-sm-95 {
    margin-top: 95px !important; }
  .mr-sm-95,
  .mx-sm-95 {
    margin-right: 95px !important; }
  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important; }
  .ml-sm-95,
  .mx-sm-95 {
    margin-left: 95px !important; }
  .m-sm-100 {
    margin: 100px !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100px !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100px !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 2.5px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 2.5px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 2.5px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 2.5px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 2.5px !important; }
  .p-sm-2 {
    padding: 5px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 5px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 5px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 5px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 5px !important; }
  .p-sm-3 {
    padding: 10px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 10px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 10px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 10px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 10px !important; }
  .p-sm-4 {
    padding: 15px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 15px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 15px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 15px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 15px !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important; }
  .p-sm-55 {
    padding: 55px !important; }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 55px !important; }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 55px !important; }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important; }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 55px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 60px !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 60px !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 60px !important; }
  .p-sm-65 {
    padding: 65px !important; }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 65px !important; }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 65px !important; }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important; }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 65px !important; }
  .p-sm-70 {
    padding: 70px !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 70px !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 70px !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 70px !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 70px !important; }
  .p-sm-75 {
    padding: 75px !important; }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 75px !important; }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 75px !important; }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75px !important; }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 75px !important; }
  .p-sm-80 {
    padding: 80px !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 80px !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 80px !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 80px !important; }
  .p-sm-85 {
    padding: 85px !important; }
  .pt-sm-85,
  .py-sm-85 {
    padding-top: 85px !important; }
  .pr-sm-85,
  .px-sm-85 {
    padding-right: 85px !important; }
  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important; }
  .pl-sm-85,
  .px-sm-85 {
    padding-left: 85px !important; }
  .p-sm-90 {
    padding: 90px !important; }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 90px !important; }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 90px !important; }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important; }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 90px !important; }
  .p-sm-95 {
    padding: 95px !important; }
  .pt-sm-95,
  .py-sm-95 {
    padding-top: 95px !important; }
  .pr-sm-95,
  .px-sm-95 {
    padding-right: 95px !important; }
  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important; }
  .pl-sm-95,
  .px-sm-95 {
    padding-left: 95px !important; }
  .p-sm-100 {
    padding: 100px !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100px !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100px !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100px !important; }
  .m-sm-n1 {
    margin: -5px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -5px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -5px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -5px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -5px !important; }
  .m-sm-n2 {
    margin: -5px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -5px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -5px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -5px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -5px !important; }
  .m-sm-n3 {
    margin: -5px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -5px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -5px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -5px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -5px !important; }
  .m-sm-n4 {
    margin: -5px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -5px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -5px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -5px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -5px !important; }
  .m-sm-n5 {
    margin: -5px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5px !important; }
  .m-sm-n10 {
    margin: -5px !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -5px !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -5px !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -5px !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -5px !important; }
  .m-sm-n15 {
    margin: -5px !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -5px !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -5px !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -5px !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -5px !important; }
  .m-sm-n20 {
    margin: -5px !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -5px !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -5px !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -5px !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -5px !important; }
  .m-sm-n25 {
    margin: -5px !important; }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -5px !important; }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -5px !important; }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -5px !important; }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -5px !important; }
  .m-sm-n30 {
    margin: -5px !important; }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -5px !important; }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -5px !important; }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -5px !important; }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -5px !important; }
  .m-sm-n35 {
    margin: -5px !important; }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -5px !important; }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -5px !important; }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -5px !important; }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -5px !important; }
  .m-sm-n40 {
    margin: -5px !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -5px !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -5px !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -5px !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -5px !important; }
  .m-sm-n45 {
    margin: -5px !important; }
  .mt-sm-n45,
  .my-sm-n45 {
    margin-top: -5px !important; }
  .mr-sm-n45,
  .mx-sm-n45 {
    margin-right: -5px !important; }
  .mb-sm-n45,
  .my-sm-n45 {
    margin-bottom: -5px !important; }
  .ml-sm-n45,
  .mx-sm-n45 {
    margin-left: -5px !important; }
  .m-sm-n50 {
    margin: -5px !important; }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -5px !important; }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -5px !important; }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -5px !important; }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -5px !important; }
  .m-sm-n55 {
    margin: -5px !important; }
  .mt-sm-n55,
  .my-sm-n55 {
    margin-top: -5px !important; }
  .mr-sm-n55,
  .mx-sm-n55 {
    margin-right: -5px !important; }
  .mb-sm-n55,
  .my-sm-n55 {
    margin-bottom: -5px !important; }
  .ml-sm-n55,
  .mx-sm-n55 {
    margin-left: -5px !important; }
  .m-sm-n60 {
    margin: -5px !important; }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -5px !important; }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -5px !important; }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -5px !important; }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -5px !important; }
  .m-sm-n65 {
    margin: -5px !important; }
  .mt-sm-n65,
  .my-sm-n65 {
    margin-top: -5px !important; }
  .mr-sm-n65,
  .mx-sm-n65 {
    margin-right: -5px !important; }
  .mb-sm-n65,
  .my-sm-n65 {
    margin-bottom: -5px !important; }
  .ml-sm-n65,
  .mx-sm-n65 {
    margin-left: -5px !important; }
  .m-sm-n70 {
    margin: -5px !important; }
  .mt-sm-n70,
  .my-sm-n70 {
    margin-top: -5px !important; }
  .mr-sm-n70,
  .mx-sm-n70 {
    margin-right: -5px !important; }
  .mb-sm-n70,
  .my-sm-n70 {
    margin-bottom: -5px !important; }
  .ml-sm-n70,
  .mx-sm-n70 {
    margin-left: -5px !important; }
  .m-sm-n75 {
    margin: -5px !important; }
  .mt-sm-n75,
  .my-sm-n75 {
    margin-top: -5px !important; }
  .mr-sm-n75,
  .mx-sm-n75 {
    margin-right: -5px !important; }
  .mb-sm-n75,
  .my-sm-n75 {
    margin-bottom: -5px !important; }
  .ml-sm-n75,
  .mx-sm-n75 {
    margin-left: -5px !important; }
  .m-sm-n80 {
    margin: -5px !important; }
  .mt-sm-n80,
  .my-sm-n80 {
    margin-top: -5px !important; }
  .mr-sm-n80,
  .mx-sm-n80 {
    margin-right: -5px !important; }
  .mb-sm-n80,
  .my-sm-n80 {
    margin-bottom: -5px !important; }
  .ml-sm-n80,
  .mx-sm-n80 {
    margin-left: -5px !important; }
  .m-sm-n85 {
    margin: -5px !important; }
  .mt-sm-n85,
  .my-sm-n85 {
    margin-top: -5px !important; }
  .mr-sm-n85,
  .mx-sm-n85 {
    margin-right: -5px !important; }
  .mb-sm-n85,
  .my-sm-n85 {
    margin-bottom: -5px !important; }
  .ml-sm-n85,
  .mx-sm-n85 {
    margin-left: -5px !important; }
  .m-sm-n90 {
    margin: -5px !important; }
  .mt-sm-n90,
  .my-sm-n90 {
    margin-top: -5px !important; }
  .mr-sm-n90,
  .mx-sm-n90 {
    margin-right: -5px !important; }
  .mb-sm-n90,
  .my-sm-n90 {
    margin-bottom: -5px !important; }
  .ml-sm-n90,
  .mx-sm-n90 {
    margin-left: -5px !important; }
  .m-sm-n95 {
    margin: -5px !important; }
  .mt-sm-n95,
  .my-sm-n95 {
    margin-top: -5px !important; }
  .mr-sm-n95,
  .mx-sm-n95 {
    margin-right: -5px !important; }
  .mb-sm-n95,
  .my-sm-n95 {
    margin-bottom: -5px !important; }
  .ml-sm-n95,
  .mx-sm-n95 {
    margin-left: -5px !important; }
  .m-sm-n100 {
    margin: -5px !important; }
  .mt-sm-n100,
  .my-sm-n100 {
    margin-top: -5px !important; }
  .mr-sm-n100,
  .mx-sm-n100 {
    margin-right: -5px !important; }
  .mb-sm-n100,
  .my-sm-n100 {
    margin-bottom: -5px !important; }
  .ml-sm-n100,
  .mx-sm-n100 {
    margin-left: -5px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 2.5px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 2.5px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 2.5px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 2.5px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 2.5px !important; }
  .m-md-2 {
    margin: 5px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 5px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 5px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 5px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 5px !important; }
  .m-md-3 {
    margin: 10px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 10px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 10px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 10px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 10px !important; }
  .m-md-4 {
    margin: 15px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 15px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 15px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 15px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 15px !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important; }
  .m-md-55 {
    margin: 55px !important; }
  .mt-md-55,
  .my-md-55 {
    margin-top: 55px !important; }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 55px !important; }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important; }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 55px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 60px !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 60px !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 60px !important; }
  .m-md-65 {
    margin: 65px !important; }
  .mt-md-65,
  .my-md-65 {
    margin-top: 65px !important; }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 65px !important; }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important; }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 65px !important; }
  .m-md-70 {
    margin: 70px !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 70px !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 70px !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 70px !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 70px !important; }
  .m-md-75 {
    margin: 75px !important; }
  .mt-md-75,
  .my-md-75 {
    margin-top: 75px !important; }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 75px !important; }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75px !important; }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 75px !important; }
  .m-md-80 {
    margin: 80px !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 80px !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 80px !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 80px !important; }
  .m-md-85 {
    margin: 85px !important; }
  .mt-md-85,
  .my-md-85 {
    margin-top: 85px !important; }
  .mr-md-85,
  .mx-md-85 {
    margin-right: 85px !important; }
  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important; }
  .ml-md-85,
  .mx-md-85 {
    margin-left: 85px !important; }
  .m-md-90 {
    margin: 90px !important; }
  .mt-md-90,
  .my-md-90 {
    margin-top: 90px !important; }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 90px !important; }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important; }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 90px !important; }
  .m-md-95 {
    margin: 95px !important; }
  .mt-md-95,
  .my-md-95 {
    margin-top: 95px !important; }
  .mr-md-95,
  .mx-md-95 {
    margin-right: 95px !important; }
  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important; }
  .ml-md-95,
  .mx-md-95 {
    margin-left: 95px !important; }
  .m-md-100 {
    margin: 100px !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100px !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100px !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 2.5px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 2.5px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 2.5px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 2.5px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 2.5px !important; }
  .p-md-2 {
    padding: 5px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 5px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 5px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 5px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 5px !important; }
  .p-md-3 {
    padding: 10px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 10px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 10px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 10px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 10px !important; }
  .p-md-4 {
    padding: 15px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 15px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 15px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 15px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 15px !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important; }
  .p-md-55 {
    padding: 55px !important; }
  .pt-md-55,
  .py-md-55 {
    padding-top: 55px !important; }
  .pr-md-55,
  .px-md-55 {
    padding-right: 55px !important; }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important; }
  .pl-md-55,
  .px-md-55 {
    padding-left: 55px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 60px !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 60px !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 60px !important; }
  .p-md-65 {
    padding: 65px !important; }
  .pt-md-65,
  .py-md-65 {
    padding-top: 65px !important; }
  .pr-md-65,
  .px-md-65 {
    padding-right: 65px !important; }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important; }
  .pl-md-65,
  .px-md-65 {
    padding-left: 65px !important; }
  .p-md-70 {
    padding: 70px !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 70px !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 70px !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 70px !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 70px !important; }
  .p-md-75 {
    padding: 75px !important; }
  .pt-md-75,
  .py-md-75 {
    padding-top: 75px !important; }
  .pr-md-75,
  .px-md-75 {
    padding-right: 75px !important; }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75px !important; }
  .pl-md-75,
  .px-md-75 {
    padding-left: 75px !important; }
  .p-md-80 {
    padding: 80px !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 80px !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 80px !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 80px !important; }
  .p-md-85 {
    padding: 85px !important; }
  .pt-md-85,
  .py-md-85 {
    padding-top: 85px !important; }
  .pr-md-85,
  .px-md-85 {
    padding-right: 85px !important; }
  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important; }
  .pl-md-85,
  .px-md-85 {
    padding-left: 85px !important; }
  .p-md-90 {
    padding: 90px !important; }
  .pt-md-90,
  .py-md-90 {
    padding-top: 90px !important; }
  .pr-md-90,
  .px-md-90 {
    padding-right: 90px !important; }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important; }
  .pl-md-90,
  .px-md-90 {
    padding-left: 90px !important; }
  .p-md-95 {
    padding: 95px !important; }
  .pt-md-95,
  .py-md-95 {
    padding-top: 95px !important; }
  .pr-md-95,
  .px-md-95 {
    padding-right: 95px !important; }
  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important; }
  .pl-md-95,
  .px-md-95 {
    padding-left: 95px !important; }
  .p-md-100 {
    padding: 100px !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100px !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100px !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100px !important; }
  .m-md-n1 {
    margin: -5px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -5px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -5px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -5px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -5px !important; }
  .m-md-n2 {
    margin: -5px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -5px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -5px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -5px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -5px !important; }
  .m-md-n3 {
    margin: -5px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -5px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -5px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -5px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -5px !important; }
  .m-md-n4 {
    margin: -5px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -5px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -5px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -5px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -5px !important; }
  .m-md-n5 {
    margin: -5px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5px !important; }
  .m-md-n10 {
    margin: -5px !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5px !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -5px !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5px !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -5px !important; }
  .m-md-n15 {
    margin: -5px !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -5px !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -5px !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -5px !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -5px !important; }
  .m-md-n20 {
    margin: -5px !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -5px !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -5px !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -5px !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -5px !important; }
  .m-md-n25 {
    margin: -5px !important; }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -5px !important; }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -5px !important; }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -5px !important; }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -5px !important; }
  .m-md-n30 {
    margin: -5px !important; }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -5px !important; }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -5px !important; }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -5px !important; }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -5px !important; }
  .m-md-n35 {
    margin: -5px !important; }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -5px !important; }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -5px !important; }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -5px !important; }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -5px !important; }
  .m-md-n40 {
    margin: -5px !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -5px !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -5px !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -5px !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -5px !important; }
  .m-md-n45 {
    margin: -5px !important; }
  .mt-md-n45,
  .my-md-n45 {
    margin-top: -5px !important; }
  .mr-md-n45,
  .mx-md-n45 {
    margin-right: -5px !important; }
  .mb-md-n45,
  .my-md-n45 {
    margin-bottom: -5px !important; }
  .ml-md-n45,
  .mx-md-n45 {
    margin-left: -5px !important; }
  .m-md-n50 {
    margin: -5px !important; }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -5px !important; }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -5px !important; }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -5px !important; }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -5px !important; }
  .m-md-n55 {
    margin: -5px !important; }
  .mt-md-n55,
  .my-md-n55 {
    margin-top: -5px !important; }
  .mr-md-n55,
  .mx-md-n55 {
    margin-right: -5px !important; }
  .mb-md-n55,
  .my-md-n55 {
    margin-bottom: -5px !important; }
  .ml-md-n55,
  .mx-md-n55 {
    margin-left: -5px !important; }
  .m-md-n60 {
    margin: -5px !important; }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -5px !important; }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -5px !important; }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -5px !important; }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -5px !important; }
  .m-md-n65 {
    margin: -5px !important; }
  .mt-md-n65,
  .my-md-n65 {
    margin-top: -5px !important; }
  .mr-md-n65,
  .mx-md-n65 {
    margin-right: -5px !important; }
  .mb-md-n65,
  .my-md-n65 {
    margin-bottom: -5px !important; }
  .ml-md-n65,
  .mx-md-n65 {
    margin-left: -5px !important; }
  .m-md-n70 {
    margin: -5px !important; }
  .mt-md-n70,
  .my-md-n70 {
    margin-top: -5px !important; }
  .mr-md-n70,
  .mx-md-n70 {
    margin-right: -5px !important; }
  .mb-md-n70,
  .my-md-n70 {
    margin-bottom: -5px !important; }
  .ml-md-n70,
  .mx-md-n70 {
    margin-left: -5px !important; }
  .m-md-n75 {
    margin: -5px !important; }
  .mt-md-n75,
  .my-md-n75 {
    margin-top: -5px !important; }
  .mr-md-n75,
  .mx-md-n75 {
    margin-right: -5px !important; }
  .mb-md-n75,
  .my-md-n75 {
    margin-bottom: -5px !important; }
  .ml-md-n75,
  .mx-md-n75 {
    margin-left: -5px !important; }
  .m-md-n80 {
    margin: -5px !important; }
  .mt-md-n80,
  .my-md-n80 {
    margin-top: -5px !important; }
  .mr-md-n80,
  .mx-md-n80 {
    margin-right: -5px !important; }
  .mb-md-n80,
  .my-md-n80 {
    margin-bottom: -5px !important; }
  .ml-md-n80,
  .mx-md-n80 {
    margin-left: -5px !important; }
  .m-md-n85 {
    margin: -5px !important; }
  .mt-md-n85,
  .my-md-n85 {
    margin-top: -5px !important; }
  .mr-md-n85,
  .mx-md-n85 {
    margin-right: -5px !important; }
  .mb-md-n85,
  .my-md-n85 {
    margin-bottom: -5px !important; }
  .ml-md-n85,
  .mx-md-n85 {
    margin-left: -5px !important; }
  .m-md-n90 {
    margin: -5px !important; }
  .mt-md-n90,
  .my-md-n90 {
    margin-top: -5px !important; }
  .mr-md-n90,
  .mx-md-n90 {
    margin-right: -5px !important; }
  .mb-md-n90,
  .my-md-n90 {
    margin-bottom: -5px !important; }
  .ml-md-n90,
  .mx-md-n90 {
    margin-left: -5px !important; }
  .m-md-n95 {
    margin: -5px !important; }
  .mt-md-n95,
  .my-md-n95 {
    margin-top: -5px !important; }
  .mr-md-n95,
  .mx-md-n95 {
    margin-right: -5px !important; }
  .mb-md-n95,
  .my-md-n95 {
    margin-bottom: -5px !important; }
  .ml-md-n95,
  .mx-md-n95 {
    margin-left: -5px !important; }
  .m-md-n100 {
    margin: -5px !important; }
  .mt-md-n100,
  .my-md-n100 {
    margin-top: -5px !important; }
  .mr-md-n100,
  .mx-md-n100 {
    margin-right: -5px !important; }
  .mb-md-n100,
  .my-md-n100 {
    margin-bottom: -5px !important; }
  .ml-md-n100,
  .mx-md-n100 {
    margin-left: -5px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 2.5px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 2.5px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 2.5px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 2.5px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 2.5px !important; }
  .m-lg-2 {
    margin: 5px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 5px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 5px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 5px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 5px !important; }
  .m-lg-3 {
    margin: 10px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 10px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 10px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 10px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 10px !important; }
  .m-lg-4 {
    margin: 15px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 15px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 15px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 15px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 15px !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important; }
  .m-lg-55 {
    margin: 55px !important; }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 55px !important; }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 55px !important; }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important; }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 55px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 60px !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 60px !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 60px !important; }
  .m-lg-65 {
    margin: 65px !important; }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 65px !important; }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 65px !important; }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important; }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 65px !important; }
  .m-lg-70 {
    margin: 70px !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 70px !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 70px !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 70px !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 70px !important; }
  .m-lg-75 {
    margin: 75px !important; }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 75px !important; }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 75px !important; }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75px !important; }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 75px !important; }
  .m-lg-80 {
    margin: 80px !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 80px !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 80px !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 80px !important; }
  .m-lg-85 {
    margin: 85px !important; }
  .mt-lg-85,
  .my-lg-85 {
    margin-top: 85px !important; }
  .mr-lg-85,
  .mx-lg-85 {
    margin-right: 85px !important; }
  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important; }
  .ml-lg-85,
  .mx-lg-85 {
    margin-left: 85px !important; }
  .m-lg-90 {
    margin: 90px !important; }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 90px !important; }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 90px !important; }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important; }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 90px !important; }
  .m-lg-95 {
    margin: 95px !important; }
  .mt-lg-95,
  .my-lg-95 {
    margin-top: 95px !important; }
  .mr-lg-95,
  .mx-lg-95 {
    margin-right: 95px !important; }
  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important; }
  .ml-lg-95,
  .mx-lg-95 {
    margin-left: 95px !important; }
  .m-lg-100 {
    margin: 100px !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100px !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100px !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 2.5px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 2.5px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 2.5px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 2.5px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 2.5px !important; }
  .p-lg-2 {
    padding: 5px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 5px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 5px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 5px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 5px !important; }
  .p-lg-3 {
    padding: 10px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 10px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 10px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 10px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 10px !important; }
  .p-lg-4 {
    padding: 15px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 15px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 15px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 15px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 15px !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important; }
  .p-lg-55 {
    padding: 55px !important; }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 55px !important; }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 55px !important; }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important; }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 55px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 60px !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 60px !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 60px !important; }
  .p-lg-65 {
    padding: 65px !important; }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 65px !important; }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 65px !important; }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important; }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 65px !important; }
  .p-lg-70 {
    padding: 70px !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 70px !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 70px !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 70px !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 70px !important; }
  .p-lg-75 {
    padding: 75px !important; }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 75px !important; }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 75px !important; }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75px !important; }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 75px !important; }
  .p-lg-80 {
    padding: 80px !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 80px !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 80px !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 80px !important; }
  .p-lg-85 {
    padding: 85px !important; }
  .pt-lg-85,
  .py-lg-85 {
    padding-top: 85px !important; }
  .pr-lg-85,
  .px-lg-85 {
    padding-right: 85px !important; }
  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important; }
  .pl-lg-85,
  .px-lg-85 {
    padding-left: 85px !important; }
  .p-lg-90 {
    padding: 90px !important; }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 90px !important; }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 90px !important; }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important; }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 90px !important; }
  .p-lg-95 {
    padding: 95px !important; }
  .pt-lg-95,
  .py-lg-95 {
    padding-top: 95px !important; }
  .pr-lg-95,
  .px-lg-95 {
    padding-right: 95px !important; }
  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important; }
  .pl-lg-95,
  .px-lg-95 {
    padding-left: 95px !important; }
  .p-lg-100 {
    padding: 100px !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100px !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100px !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100px !important; }
  .m-lg-n1 {
    margin: -5px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -5px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -5px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -5px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -5px !important; }
  .m-lg-n2 {
    margin: -5px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -5px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -5px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -5px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -5px !important; }
  .m-lg-n3 {
    margin: -5px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -5px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -5px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -5px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -5px !important; }
  .m-lg-n4 {
    margin: -5px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -5px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -5px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -5px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -5px !important; }
  .m-lg-n5 {
    margin: -5px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5px !important; }
  .m-lg-n10 {
    margin: -5px !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5px !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -5px !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5px !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -5px !important; }
  .m-lg-n15 {
    margin: -5px !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -5px !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -5px !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -5px !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -5px !important; }
  .m-lg-n20 {
    margin: -5px !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -5px !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -5px !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -5px !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -5px !important; }
  .m-lg-n25 {
    margin: -5px !important; }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -5px !important; }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -5px !important; }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -5px !important; }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -5px !important; }
  .m-lg-n30 {
    margin: -5px !important; }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -5px !important; }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -5px !important; }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -5px !important; }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -5px !important; }
  .m-lg-n35 {
    margin: -5px !important; }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -5px !important; }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -5px !important; }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -5px !important; }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -5px !important; }
  .m-lg-n40 {
    margin: -5px !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -5px !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -5px !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -5px !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -5px !important; }
  .m-lg-n45 {
    margin: -5px !important; }
  .mt-lg-n45,
  .my-lg-n45 {
    margin-top: -5px !important; }
  .mr-lg-n45,
  .mx-lg-n45 {
    margin-right: -5px !important; }
  .mb-lg-n45,
  .my-lg-n45 {
    margin-bottom: -5px !important; }
  .ml-lg-n45,
  .mx-lg-n45 {
    margin-left: -5px !important; }
  .m-lg-n50 {
    margin: -5px !important; }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -5px !important; }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -5px !important; }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -5px !important; }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -5px !important; }
  .m-lg-n55 {
    margin: -5px !important; }
  .mt-lg-n55,
  .my-lg-n55 {
    margin-top: -5px !important; }
  .mr-lg-n55,
  .mx-lg-n55 {
    margin-right: -5px !important; }
  .mb-lg-n55,
  .my-lg-n55 {
    margin-bottom: -5px !important; }
  .ml-lg-n55,
  .mx-lg-n55 {
    margin-left: -5px !important; }
  .m-lg-n60 {
    margin: -5px !important; }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -5px !important; }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -5px !important; }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -5px !important; }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -5px !important; }
  .m-lg-n65 {
    margin: -5px !important; }
  .mt-lg-n65,
  .my-lg-n65 {
    margin-top: -5px !important; }
  .mr-lg-n65,
  .mx-lg-n65 {
    margin-right: -5px !important; }
  .mb-lg-n65,
  .my-lg-n65 {
    margin-bottom: -5px !important; }
  .ml-lg-n65,
  .mx-lg-n65 {
    margin-left: -5px !important; }
  .m-lg-n70 {
    margin: -5px !important; }
  .mt-lg-n70,
  .my-lg-n70 {
    margin-top: -5px !important; }
  .mr-lg-n70,
  .mx-lg-n70 {
    margin-right: -5px !important; }
  .mb-lg-n70,
  .my-lg-n70 {
    margin-bottom: -5px !important; }
  .ml-lg-n70,
  .mx-lg-n70 {
    margin-left: -5px !important; }
  .m-lg-n75 {
    margin: -5px !important; }
  .mt-lg-n75,
  .my-lg-n75 {
    margin-top: -5px !important; }
  .mr-lg-n75,
  .mx-lg-n75 {
    margin-right: -5px !important; }
  .mb-lg-n75,
  .my-lg-n75 {
    margin-bottom: -5px !important; }
  .ml-lg-n75,
  .mx-lg-n75 {
    margin-left: -5px !important; }
  .m-lg-n80 {
    margin: -5px !important; }
  .mt-lg-n80,
  .my-lg-n80 {
    margin-top: -5px !important; }
  .mr-lg-n80,
  .mx-lg-n80 {
    margin-right: -5px !important; }
  .mb-lg-n80,
  .my-lg-n80 {
    margin-bottom: -5px !important; }
  .ml-lg-n80,
  .mx-lg-n80 {
    margin-left: -5px !important; }
  .m-lg-n85 {
    margin: -5px !important; }
  .mt-lg-n85,
  .my-lg-n85 {
    margin-top: -5px !important; }
  .mr-lg-n85,
  .mx-lg-n85 {
    margin-right: -5px !important; }
  .mb-lg-n85,
  .my-lg-n85 {
    margin-bottom: -5px !important; }
  .ml-lg-n85,
  .mx-lg-n85 {
    margin-left: -5px !important; }
  .m-lg-n90 {
    margin: -5px !important; }
  .mt-lg-n90,
  .my-lg-n90 {
    margin-top: -5px !important; }
  .mr-lg-n90,
  .mx-lg-n90 {
    margin-right: -5px !important; }
  .mb-lg-n90,
  .my-lg-n90 {
    margin-bottom: -5px !important; }
  .ml-lg-n90,
  .mx-lg-n90 {
    margin-left: -5px !important; }
  .m-lg-n95 {
    margin: -5px !important; }
  .mt-lg-n95,
  .my-lg-n95 {
    margin-top: -5px !important; }
  .mr-lg-n95,
  .mx-lg-n95 {
    margin-right: -5px !important; }
  .mb-lg-n95,
  .my-lg-n95 {
    margin-bottom: -5px !important; }
  .ml-lg-n95,
  .mx-lg-n95 {
    margin-left: -5px !important; }
  .m-lg-n100 {
    margin: -5px !important; }
  .mt-lg-n100,
  .my-lg-n100 {
    margin-top: -5px !important; }
  .mr-lg-n100,
  .mx-lg-n100 {
    margin-right: -5px !important; }
  .mb-lg-n100,
  .my-lg-n100 {
    margin-bottom: -5px !important; }
  .ml-lg-n100,
  .mx-lg-n100 {
    margin-left: -5px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 2.5px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 2.5px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 2.5px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 2.5px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 2.5px !important; }
  .m-xl-2 {
    margin: 5px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 5px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 5px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 5px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 5px !important; }
  .m-xl-3 {
    margin: 10px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 10px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 10px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 10px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 10px !important; }
  .m-xl-4 {
    margin: 15px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 15px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 15px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 15px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 15px !important; }
  .m-xl-5 {
    margin: 5px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important; }
  .m-xl-10 {
    margin: 10px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important; }
  .m-xl-15 {
    margin: 15px !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }
  .m-xl-20 {
    margin: 20px !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important; }
  .m-xl-25 {
    margin: 25px !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important; }
  .m-xl-30 {
    margin: 30px !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important; }
  .m-xl-35 {
    margin: 35px !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important; }
  .m-xl-40 {
    margin: 40px !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important; }
  .m-xl-45 {
    margin: 45px !important; }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important; }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important; }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important; }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important; }
  .m-xl-50 {
    margin: 50px !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important; }
  .m-xl-55 {
    margin: 55px !important; }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 55px !important; }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 55px !important; }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important; }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 55px !important; }
  .m-xl-60 {
    margin: 60px !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 60px !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 60px !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 60px !important; }
  .m-xl-65 {
    margin: 65px !important; }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 65px !important; }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 65px !important; }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important; }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 65px !important; }
  .m-xl-70 {
    margin: 70px !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 70px !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 70px !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 70px !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 70px !important; }
  .m-xl-75 {
    margin: 75px !important; }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 75px !important; }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 75px !important; }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75px !important; }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 75px !important; }
  .m-xl-80 {
    margin: 80px !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 80px !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 80px !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 80px !important; }
  .m-xl-85 {
    margin: 85px !important; }
  .mt-xl-85,
  .my-xl-85 {
    margin-top: 85px !important; }
  .mr-xl-85,
  .mx-xl-85 {
    margin-right: 85px !important; }
  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important; }
  .ml-xl-85,
  .mx-xl-85 {
    margin-left: 85px !important; }
  .m-xl-90 {
    margin: 90px !important; }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 90px !important; }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 90px !important; }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important; }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 90px !important; }
  .m-xl-95 {
    margin: 95px !important; }
  .mt-xl-95,
  .my-xl-95 {
    margin-top: 95px !important; }
  .mr-xl-95,
  .mx-xl-95 {
    margin-right: 95px !important; }
  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important; }
  .ml-xl-95,
  .mx-xl-95 {
    margin-left: 95px !important; }
  .m-xl-100 {
    margin: 100px !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100px !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100px !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 2.5px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 2.5px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 2.5px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 2.5px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 2.5px !important; }
  .p-xl-2 {
    padding: 5px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 5px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 5px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 5px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 5px !important; }
  .p-xl-3 {
    padding: 10px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 10px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 10px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 10px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 10px !important; }
  .p-xl-4 {
    padding: 15px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 15px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 15px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 15px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 15px !important; }
  .p-xl-5 {
    padding: 5px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important; }
  .p-xl-10 {
    padding: 10px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important; }
  .p-xl-15 {
    padding: 15px !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }
  .p-xl-20 {
    padding: 20px !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important; }
  .p-xl-25 {
    padding: 25px !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important; }
  .p-xl-30 {
    padding: 30px !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important; }
  .p-xl-35 {
    padding: 35px !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important; }
  .p-xl-40 {
    padding: 40px !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important; }
  .p-xl-45 {
    padding: 45px !important; }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important; }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important; }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important; }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important; }
  .p-xl-50 {
    padding: 50px !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important; }
  .p-xl-55 {
    padding: 55px !important; }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 55px !important; }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 55px !important; }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important; }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 55px !important; }
  .p-xl-60 {
    padding: 60px !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 60px !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 60px !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 60px !important; }
  .p-xl-65 {
    padding: 65px !important; }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 65px !important; }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 65px !important; }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important; }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 65px !important; }
  .p-xl-70 {
    padding: 70px !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 70px !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 70px !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 70px !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 70px !important; }
  .p-xl-75 {
    padding: 75px !important; }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 75px !important; }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 75px !important; }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75px !important; }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 75px !important; }
  .p-xl-80 {
    padding: 80px !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 80px !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 80px !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 80px !important; }
  .p-xl-85 {
    padding: 85px !important; }
  .pt-xl-85,
  .py-xl-85 {
    padding-top: 85px !important; }
  .pr-xl-85,
  .px-xl-85 {
    padding-right: 85px !important; }
  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important; }
  .pl-xl-85,
  .px-xl-85 {
    padding-left: 85px !important; }
  .p-xl-90 {
    padding: 90px !important; }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 90px !important; }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 90px !important; }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important; }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 90px !important; }
  .p-xl-95 {
    padding: 95px !important; }
  .pt-xl-95,
  .py-xl-95 {
    padding-top: 95px !important; }
  .pr-xl-95,
  .px-xl-95 {
    padding-right: 95px !important; }
  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important; }
  .pl-xl-95,
  .px-xl-95 {
    padding-left: 95px !important; }
  .p-xl-100 {
    padding: 100px !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100px !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100px !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100px !important; }
  .m-xl-n1 {
    margin: -5px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -5px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -5px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -5px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -5px !important; }
  .m-xl-n2 {
    margin: -5px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -5px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -5px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -5px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -5px !important; }
  .m-xl-n3 {
    margin: -5px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -5px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -5px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -5px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -5px !important; }
  .m-xl-n4 {
    margin: -5px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -5px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -5px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -5px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -5px !important; }
  .m-xl-n5 {
    margin: -5px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5px !important; }
  .m-xl-n10 {
    margin: -5px !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5px !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -5px !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5px !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -5px !important; }
  .m-xl-n15 {
    margin: -5px !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -5px !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -5px !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -5px !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -5px !important; }
  .m-xl-n20 {
    margin: -5px !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -5px !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -5px !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -5px !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -5px !important; }
  .m-xl-n25 {
    margin: -5px !important; }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -5px !important; }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -5px !important; }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -5px !important; }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -5px !important; }
  .m-xl-n30 {
    margin: -5px !important; }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -5px !important; }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -5px !important; }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -5px !important; }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -5px !important; }
  .m-xl-n35 {
    margin: -5px !important; }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -5px !important; }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -5px !important; }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -5px !important; }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -5px !important; }
  .m-xl-n40 {
    margin: -5px !important; }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -5px !important; }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -5px !important; }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -5px !important; }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -5px !important; }
  .m-xl-n45 {
    margin: -5px !important; }
  .mt-xl-n45,
  .my-xl-n45 {
    margin-top: -5px !important; }
  .mr-xl-n45,
  .mx-xl-n45 {
    margin-right: -5px !important; }
  .mb-xl-n45,
  .my-xl-n45 {
    margin-bottom: -5px !important; }
  .ml-xl-n45,
  .mx-xl-n45 {
    margin-left: -5px !important; }
  .m-xl-n50 {
    margin: -5px !important; }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -5px !important; }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -5px !important; }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -5px !important; }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -5px !important; }
  .m-xl-n55 {
    margin: -5px !important; }
  .mt-xl-n55,
  .my-xl-n55 {
    margin-top: -5px !important; }
  .mr-xl-n55,
  .mx-xl-n55 {
    margin-right: -5px !important; }
  .mb-xl-n55,
  .my-xl-n55 {
    margin-bottom: -5px !important; }
  .ml-xl-n55,
  .mx-xl-n55 {
    margin-left: -5px !important; }
  .m-xl-n60 {
    margin: -5px !important; }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -5px !important; }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -5px !important; }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -5px !important; }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -5px !important; }
  .m-xl-n65 {
    margin: -5px !important; }
  .mt-xl-n65,
  .my-xl-n65 {
    margin-top: -5px !important; }
  .mr-xl-n65,
  .mx-xl-n65 {
    margin-right: -5px !important; }
  .mb-xl-n65,
  .my-xl-n65 {
    margin-bottom: -5px !important; }
  .ml-xl-n65,
  .mx-xl-n65 {
    margin-left: -5px !important; }
  .m-xl-n70 {
    margin: -5px !important; }
  .mt-xl-n70,
  .my-xl-n70 {
    margin-top: -5px !important; }
  .mr-xl-n70,
  .mx-xl-n70 {
    margin-right: -5px !important; }
  .mb-xl-n70,
  .my-xl-n70 {
    margin-bottom: -5px !important; }
  .ml-xl-n70,
  .mx-xl-n70 {
    margin-left: -5px !important; }
  .m-xl-n75 {
    margin: -5px !important; }
  .mt-xl-n75,
  .my-xl-n75 {
    margin-top: -5px !important; }
  .mr-xl-n75,
  .mx-xl-n75 {
    margin-right: -5px !important; }
  .mb-xl-n75,
  .my-xl-n75 {
    margin-bottom: -5px !important; }
  .ml-xl-n75,
  .mx-xl-n75 {
    margin-left: -5px !important; }
  .m-xl-n80 {
    margin: -5px !important; }
  .mt-xl-n80,
  .my-xl-n80 {
    margin-top: -5px !important; }
  .mr-xl-n80,
  .mx-xl-n80 {
    margin-right: -5px !important; }
  .mb-xl-n80,
  .my-xl-n80 {
    margin-bottom: -5px !important; }
  .ml-xl-n80,
  .mx-xl-n80 {
    margin-left: -5px !important; }
  .m-xl-n85 {
    margin: -5px !important; }
  .mt-xl-n85,
  .my-xl-n85 {
    margin-top: -5px !important; }
  .mr-xl-n85,
  .mx-xl-n85 {
    margin-right: -5px !important; }
  .mb-xl-n85,
  .my-xl-n85 {
    margin-bottom: -5px !important; }
  .ml-xl-n85,
  .mx-xl-n85 {
    margin-left: -5px !important; }
  .m-xl-n90 {
    margin: -5px !important; }
  .mt-xl-n90,
  .my-xl-n90 {
    margin-top: -5px !important; }
  .mr-xl-n90,
  .mx-xl-n90 {
    margin-right: -5px !important; }
  .mb-xl-n90,
  .my-xl-n90 {
    margin-bottom: -5px !important; }
  .ml-xl-n90,
  .mx-xl-n90 {
    margin-left: -5px !important; }
  .m-xl-n95 {
    margin: -5px !important; }
  .mt-xl-n95,
  .my-xl-n95 {
    margin-top: -5px !important; }
  .mr-xl-n95,
  .mx-xl-n95 {
    margin-right: -5px !important; }
  .mb-xl-n95,
  .my-xl-n95 {
    margin-bottom: -5px !important; }
  .ml-xl-n95,
  .mx-xl-n95 {
    margin-left: -5px !important; }
  .m-xl-n100 {
    margin: -5px !important; }
  .mt-xl-n100,
  .my-xl-n100 {
    margin-top: -5px !important; }
  .mr-xl-n100,
  .mx-xl-n100 {
    margin-right: -5px !important; }
  .mb-xl-n100,
  .my-xl-n100 {
    margin-bottom: -5px !important; }
  .ml-xl-n100,
  .mx-xl-n100 {
    margin-left: -5px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2572f9 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #064ecc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #3f3f55 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1e1e29 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(30, 30, 47, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #1e1e2f; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Стандартный transition для hover-анимаций */
/* Миксин замены цвета placeholder у input.
Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */
/* Plugins */
/* Bootstrap */
@media (max-width: 1199.98px) {
  .container {
    padding-right: 24px;
    padding-left: 24px; } }

@media (max-width: 767.98px) {
  .container {
    padding-right: 12px;
    padding-left: 12px; } }

@media (max-width: 767.98px) {
  .text-center {
    text-align: left !important; } }

/* Global styles */
@font-face {
  font-family: 'ALS Hauss';
  src: url("../fonts/ALSHauss-Regular.woff2") format("woff2"), url("../fonts/ALSHauss-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ALS Hauss';
  src: url("../fonts/ALSHauss-Bold.woff2") format("woff2"), url("../fonts/ALSHauss-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

h1, .h1 {
  margin-bottom: 20px;
  font-size: 65px;
  line-height: 1.29;
  font-weight: 700; }
  @media (max-width: 1199.98px) {
    h1, .h1 {
      margin-bottom: 15px;
      font-size: 48px;
      line-height: 1.2; } }
  @media (max-width: 767.98px) {
    h1, .h1 {
      font-size: 34px; } }

h3, .h3 {
  margin-bottom: 29px;
  font-size: 32px;
  line-height: 1;
  font-weight: 700; }
  @media (max-width: 1199.98px) {
    h3, .h3 {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    h3, .h3 {
      margin-bottom: 8px;
      font-size: 28px; } }

p,
.p {
  margin-bottom: 12px;
  font-size: 21px;
  line-height: 1.72; }
  @media (max-width: 1199.98px) {
    p,
    .p {
      font-size: 18px;
      line-height: 1.56; } }
  @media (max-width: 767.98px) {
    p,
    .p {
      font-size: 16px; } }

.font-weight-medium {
  font-weight: 500; }

input:focus {
  outline: none; }

.nowrap {
  white-space: nowrap; }

textarea:focus {
  outline: none; }

button {
  padding: 0;
  border: 0;
  cursor: pointer; }
  button:focus {
    outline: none; }

input[type='submit'] {
  border: 0;
  cursor: pointer; }

body {
  -webkit-font-smoothing: antialiased; }

/* Blocks */
.front {
  padding: 50px 0 120px;
  background: #2572f9; }
  @media (max-width: 1199.98px) {
    .front {
      padding: 40px 0 120px; } }
  @media (max-width: 767.98px) {
    .front {
      padding: 36px 0 80px; } }
  .front__logo {
    margin-bottom: 50px;
    display: block; }
    @media (max-width: 1199.98px) {
      .front__logo {
        margin-bottom: 48px;
        max-width: 200px; } }
    @media (max-width: 767.98px) {
      .front__logo {
        margin-bottom: 40px;
        max-width: 176px; } }
  .front h1 span {
    display: inline-block; }
  .front__dscr {
    margin-top: 20px;
    margin-bottom: 80px;
    max-width: 90%;
    font-size: 36px;
    line-height: 1.33;
    color: rgba(255, 255, 255, 0.7); }
    @media (max-width: 1199.98px) {
      .front__dscr {
        margin-top: 15px;
        margin-bottom: 58px;
        font-size: 28px;
        line-height: 1.3; } }
    @media (max-width: 767.98px) {
      .front__dscr {
        font-size: 22px; } }

.logo__img {
  max-width: 100%; }

.item {
  display: flex;
  align-items: center; }
  @media (max-width: 1199.98px) {
    .item {
      right: auto !important;
      left: auto !important;
      margin-right: auto !important;
      margin-left: auto !important;
      max-width: 100%; } }
  @media (max-width: 767.98px) {
    .item {
      margin-top: 0 !important;
      margin-bottom: 60px;
      flex-direction: column;
      align-items: flex-start; } }
  @media (max-width: 767.98px) {
    .item:nth-child(2n) {
      position: relative; } }
  @media (max-width: 767.98px) {
    .item:nth-child(2n):before {
      position: absolute;
      top: -100px;
      left: -12px;
      display: block;
      width: 100vw;
      height: 300px;
      content: '';
      background: url(../img/arrow-mob--left.svg) no-repeat center;
      background-size: 100% auto; } }
  @media (max-width: 767.98px) {
    .item:nth-child(2n + 1) {
      position: relative; } }
  @media (max-width: 767.98px) {
    .item:nth-child(2n + 1):before {
      position: absolute;
      top: -100px;
      left: -12px;
      display: block;
      width: 100vw;
      height: 300px;
      content: '';
      background: url(../img/arrow-mob--right.svg) no-repeat center;
      background-size: 100% auto; } }
  @media (max-width: 767.98px) {
    .item__picture {
      margin-top: 0 !important;
      margin-right: auto !important; } }
  @media (max-width: 1199.98px) {
    .item__content {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (max-width: 767.98px) {
    .item__content {
      margin-top: 0 !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: 100% !important;
      max-width: none !important; } }
  .item--right {
    flex-direction: row-reverse; }
    @media (max-width: 767.98px) {
      .item--right {
        flex-direction: column; } }
  .item--big .item__content {
    padding-top: 12px;
    padding-left: 16px; }
    @media (max-width: 1199.98px) {
      .item--big .item__content {
        padding-left: 0; } }
  .item--vertical {
    flex-direction: column;
    align-items: center;
    flex-basis: 50%; }
    @media (max-width: 767.98px) {
      .item--vertical {
        flex-basis: 100%;
        align-self: flex-start; } }
    .item--vertical .item__content {
      margin: 0 auto;
      max-width: 456px; }
      @media (max-width: 1199.98px) {
        .item--vertical .item__content {
          max-width: 304px; } }
    .item--vertical h3 {
      margin-bottom: 12px;
      font-size: 28px; }
      @media (max-width: 1199.98px) {
        .item--vertical h3 {
          font-size: 24px; } }
    .item--vertical p {
      font-size: 16px; }
  .item--content-sm .item__content {
    max-width: 360px; }
    @media (max-width: 1199.98px) {
      .item--content-sm .item__content {
        max-width: 304px; } }
  @media (max-width: 1199.98px) {
    .item--1 {
      margin-left: -28px !important;
      width: calc(100% + 20px) !important;
      max-width: none; } }
  @media (max-width: 767.98px) {
    .item--1 {
      margin-left: 0 !important;
      width: 100% !important;
      order: 1; } }
  @media (max-width: 767.98px) {
    .item--1:before {
      top: 0 !important;
      background: url(../img/arrow-mob--left.svg) no-repeat center !important;
      background-size: 100% auto !important; } }
  .item--1 .picture__main {
    margin-left: -78px; }
  @media (max-width: 1199.98px) {
    .item--1 .item__content {
      padding-top: 0; } }
  @media (max-width: 767.98px) {
    .item--2 {
      order: 2; } }
  .item--2 .picture {
    margin-top: -43px;
    margin-right: -100px; }
    @media (max-width: 1199.98px) {
      .item--2 .picture {
        margin-top: 0;
        margin-right: 0; } }
  .item--2 .item__content {
    margin-top: -20px;
    margin-right: 0; }
  .item--3 {
    margin-left: -75px; }
    @media (max-width: 1199.98px) {
      .item--3 {
        margin-top: 0; } }
    @media (max-width: 767.98px) {
      .item--3 {
        margin-top: 0;
        order: 3; } }
    .item--3 .item__content {
      margin-top: -20px;
      margin-right: 30px; }
  .item--4 {
    margin-top: -73px;
    margin-right: -32px; }
    @media (max-width: 767.98px) {
      .item--4 {
        order: 4; } }
    .item--4 .item__content {
      margin-top: -20px;
      margin-right: 30px; }
  .item--5 {
    margin-left: -5px; }
    @media (max-width: 767.98px) {
      .item--5 {
        order: 5; } }
    .item--5 .item__content {
      margin-top: -20px; }
  .item--6 {
    position: relative;
    left: -60px;
    margin-top: -9px; }
    @media (max-width: 767.98px) {
      .item--6 {
        order: 6; } }
    .item--6 .item__content {
      margin-left: 70px; }
  .item--7 {
    margin-top: 10px;
    margin-left: 30px; }
    @media (max-width: 767.98px) {
      .item--7 {
        padding-left: 40px;
        width: 100% !important; } }
    @media (max-width: 767.98px) {
      .item--7:before {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto !important; } }
    @media (max-width: 767.98px) {
      .item--7 {
        order: 7; } }
  @media (max-width: 767.98px) {
    .item--8:before {
      background: url(../img/arrow-mob--right.svg) no-repeat center !important;
      background-size: 100% auto; } }
  @media (max-width: 767.98px) {
    .item--8 {
      order: 8; } }
  .item--8 .item__content {
    margin-top: 70px;
    margin-left: -17px; }
  .item--9 {
    margin-top: 26px;
    margin-left: -20px; }
    @media (max-width: 767.98px) {
      .item--9:before {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--9 {
        order: 9; } }
    @media (max-width: 767.98px) {
      .item--9 {
        margin-top: 150px !important; } }
    .item--9 .item__content {
      margin-top: -20px;
      margin-left: 118px; }
  .item--10 {
    position: relative;
    left: -90px;
    margin-top: 95px; }
    @media (max-width: 767.98px) {
      .item--10:before {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--10 {
        order: 10; } }
    .item--10 .item__content {
      margin-top: -20px;
      margin-left: 100px; }
  .item--11 {
    margin-left: -80px; }
    @media (max-width: 767.98px) {
      .item--11:before {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--11 {
        order: 11; } }
  .item--12 {
    position: relative;
    left: -35px; }
    @media (max-width: 767.98px) {
      .item--12 {
        order: 12; } }
    .item--12 .item__content {
      margin-top: -20px;
      margin-left: 140px; }
  .item--13 {
    position: relative;
    left: -35px; }
    @media (max-width: 767.98px) {
      .item--13 {
        order: 13; } }
    .item--13 .item__content {
      margin-top: -20px;
      margin-left: 135px; }
  .item--14 {
    margin-top: -6px;
    margin-right: -300px; }
    @media (max-width: 767.98px) {
      .item--14:before {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--14 {
        order: 14; } }
    .item--14 .item__content {
      margin-top: -15px;
      margin-left: -15px; }
    @media (max-width: 1199.98px) {
      .item--14 .picture {
        margin-right: -300px; } }
  .item--15 {
    position: relative;
    left: -52px;
    margin-top: 6px; }
    @media (max-width: 767.98px) {
      .item--15:before {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--15 {
        order: 15; } }
    .item--15 .item__content {
      margin-top: -20px; }
  .item--16 {
    position: relative;
    left: -35px;
    margin-top: -3px; }
    @media (max-width: 767.98px) {
      .item--16:before {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--16 {
        order: 16; } }
    @media (max-width: 767.98px) {
      .item--16 {
        margin-top: 100px !important; } }
    .item--16 .item__content {
      margin-top: -20px;
      margin-left: 175px; }
  .item--17 {
    margin-top: -292px;
    margin-left: -72px; }
    @media (max-width: 767.98px) {
      .item--17:before {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 1199.98px) {
      .item--17 {
        margin-top: -150px; } }
    @media (max-width: 767.98px) {
      .item--17 {
        order: 17; } }
    .item--17 .item__content {
      margin-top: -20px;
      margin-left: 70px; }
  .item--18 {
    position: relative;
    top: -5px;
    left: -40px; }
    @media (max-width: 767.98px) {
      .item--18:before {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 767.98px) {
      .item--18 {
        order: 18; } }
    .item--18 .item__content {
      margin-top: -20px;
      margin-left: 50px; }
  .item--19 {
    margin-top: -20px;
    margin-left: -80px; }
    @media (max-width: 767.98px) {
      .item--19 {
        order: 19; } }
    @media (max-width: 767.98px) {
      .item--19:before {
        right: -50% !important;
        left: auto !important;
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto; } }
    @media (max-width: 1199.98px) {
      .item--19 .picture {
        zoom: .6; } }
    .item--19 .item__content {
      margin-top: 26px; }

.footer {
  padding: 60px 0 30px; }
  @media (max-width: 991.98px) {
    .footer {
      padding: 30px 0; } }
  .footer__top {
    display: flex;
    justify-content: space-between; }
  .footer__logo {
    margin-bottom: 12px;
    display: block; }
  .footer__logo-img {
    max-width: 100%; }
  .footer__sitemap {
    margin-left: 216px;
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1; }
    @media (max-width: 991.98px) {
      .footer__sitemap {
        margin-top: 40px;
        margin-left: 0; } }
    @media (max-width: 767.98px) {
      .footer__sitemap {
        flex-flow: row wrap; } }
  @media (max-width: 767.98px) {
    .footer__sitemap-section {
      margin-bottom: 20px;
      width: 50%; } }
  .footer__sitemap-header {
    margin-bottom: 23px;
    font-size: 15px;
    line-height: 1.33;
    font-weight: 700;
    color: #1e1e2f; }
  .footer__top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .footer__phone {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    color: #1f2430; }
    @media (max-width: 767.98px) {
      .footer__phone {
        font-size: 16px;
        line-height: 1; } }
  .footer__text {
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    line-height: 1.43;
    color: #3f3f55; }
  .footer__bottom {
    margin-top: 60px;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #bdcfe4; }
    @media (max-width: 991.98px) {
      .footer__bottom {
        margin-top: 30px; } }
    @media (max-width: 767.98px) {
      .footer__bottom {
        flex-direction: column-reverse; } }
  .footer__copyright {
    font-size: 12px;
    line-height: 1.33;
    color: #868698; }
  .footer__nti {
    padding: 8px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Chevin Pro', 'Arial', sans-serif;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background: #000;
    border-radius: 3px; }
    .footer__nti:hover {
      opacity: .8;
      color: #fff; }
    .footer__nti span {
      color: #bce1ff; }
    @media (max-width: 767.98px) {
      .footer__nti {
        margin-top: 13px; } }
  .footer__bottom-right {
    display: flex; }
    @media (max-width: 767.98px) {
      .footer__bottom-right {
        margin-bottom: 24px;
        flex-direction: column; } }
  .footer__bottom-text {
    font-size: 12px;
    line-height: 1.33;
    color: #51596c; }
    .footer__bottom-text + .footer__bottom-text {
      margin-left: 30px; }
      @media (max-width: 767.98px) {
        .footer__bottom-text + .footer__bottom-text {
          margin-top: 8px;
          margin-left: 0; } }

.content {
  padding: 176px 0 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  @media (max-width: 1199.98px) {
    .content {
      padding: 63px 0; } }
  @media (max-width: 767.98px) {
    .content {
      margin-left: -12px;
      padding: 50px 12px;
      overflow: hidden;
      width: calc(100% + 24px);
      flex-direction: column; } }
  .content__more {
    margin-top: 60px;
    margin-left: auto;
    max-width: 100%; }
    @media (max-width: 1199.98px) {
      .content__more {
        margin-top: 100px;
        max-width: 332px; } }
    @media (max-width: 767.98px) {
      .content__more {
        margin-top: 50px;
        max-width: 256px;
        order: 20; } }

.socials {
  display: flex; }
  .socials__item {
    width: 36px;
    height: 36px;
    background-color: #f0f5fa;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); }
    @media (max-width: 767.98px) {
      .socials__item {
        width: 32px;
        height: 32px; } }
    .socials__item:hover {
      opacity: .8; }
    .socials__item--fb {
      background-image: url(../img/fb.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials__item--tg {
      background-image: url(../img/tg.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials__item--vk {
      background-image: url(../img/vk.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials__item--tw {
      background-image: url(../img/tw.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials__item--ok {
      background-image: url(../img/ok.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials__item + .socials__item {
      margin-left: 8px; }
  .socials--connect .socials__item {
    width: 45px;
    height: 45px;
    background: #fff; }
    @media (max-width: 1199.98px) {
      .socials--connect .socials__item {
        width: 43px;
        height: 43px; } }
    .socials--connect .socials__item + .socials__item {
      margin-left: 13px; }
    .socials--connect .socials__item--fb {
      background-image: url(../img/fb--blue.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials--connect .socials__item--tg {
      background-image: url(../img/tg--blue.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .socials--connect .socials__item--vk {
      background-image: url(../img/vk--blue.svg);
      background-repeat: no-repeat;
      background-position: center; }

@media (max-width: 1199.98px) {
  .picture {
    zoom: .7; } }

@media (max-width: 767.98px) {
  .picture {
    zoom: .6; } }

.picture__main {
  position: relative; }
  @media (max-width: 767.98px) {
    .picture__main {
      margin-top: 0 !important;
      margin-left: 0 !important; } }
  .picture__main:before {
    position: absolute;
    z-index: -1;
    display: block;
    content: ''; }
    @media (max-width: 767.98px) {
      .picture__main:before {
        display: none; } }
  .picture__main--picture1:before {
    top: -176px;
    right: -392px;
    width: 562px;
    height: 297px;
    background: url(../img/picture1-arrow.svg) no-repeat;
    background-size: contain; }
  .picture__main--picture1 .picture__part {
    top: 587px;
    left: 223px;
    opacity: 0; }
  .picture__main--picture2:before {
    top: -85px;
    left: -395px;
    width: 555px;
    height: 248px;
    background: url(../img/picture2-arrow.svg) no-repeat;
    background-size: contain; }
  .picture__main--picture2 .picture__part {
    top: 56px;
    right: -119.5px;
    opacity: 0; }
  .picture__main--picture3 {
    margin-top: 240px; }
    .picture__main--picture3:before {
      top: -85px;
      left: 120px;
      width: 675px;
      height: 218px;
      background: url(../img/picture3-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture3 .picture__part {
      top: 0;
      right: 0;
      opacity: 0; }
      .picture__main--picture3 .picture__part:nth-child(2) {
        margin-right: 152px; }
      .picture__main--picture3 .picture__part:nth-child(3) {
        margin-right: 237px; }
      .picture__main--picture3 .picture__part:nth-child(4) {
        margin-right: 322px; }
  .picture__main--picture4-5 {
    position: relative; }
    @media (max-width: 767.98px) {
      .picture__main--picture4-5 {
        left: 150px;
        margin-bottom: 150px !important; } }
    .picture__main--picture4-5 .picture__part {
      top: 17px;
      left: 230px;
      animation: tail 2s infinite; }
      @media (max-width: 767.98px) {
        .picture__main--picture4-5 .picture__part {
          left: 180px; } }

@keyframes tail {
  0% {
    transform: rotate(0);
    transform-origin: left bottom; }
  50% {
    transform: rotate(10deg);
    transform-origin: left bottom; }
  100% {
    transform: rotate(0);
    transform-origin: left bottom; } }
  .picture__main--picture4 {
    margin-top: 220px;
    margin-right: -40px; }
    .picture__main--picture4:before {
      top: -540px;
      left: -540px;
      width: 731px;
      height: 633px;
      background: url(../img/picture4-arrow.svg) no-repeat;
      background-size: contain; }
      @media (max-width: 1199.98px) {
        .picture__main--picture4:before {
          left: -460px; } }
    .picture__main--picture4 .picture__part {
      top: 0; }
      .picture__main--picture4 .picture__part--1 {
        top: -103.5px;
        left: 51px; }
      .picture__main--picture4 .picture__part--2 {
        top: -145.5px;
        left: 136px; }
      .picture__main--picture4 .picture__part--3 {
        top: -102px;
        left: 278px; }
  .picture__main--picture5 {
    margin-top: 210px;
    margin-bottom: 34px; }
    .picture__main--picture5:before {
      z-index: -2;
      top: 170px;
      left: 140px;
      width: 587px;
      height: 102px;
      background: url(../img/picture5-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture5 .picture__bg {
      position: absolute;
      z-index: -1;
      top: 50px;
      left: 38px;
      width: 263px;
      height: 262px;
      background: url(../img/picture5-bg.svg) no-repeat center;
      background-size: contain; }
    .picture__main--picture5 .picture__part {
      z-index: -1;
      top: 190px;
      left: 172px; }
      .picture__main--picture5 .picture__part svg {
        animation: sun 15s infinite linear; }

@keyframes sun {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
  .picture__main--picture6 {
    margin-top: 191px; }
    .picture__main--picture6:before {
      z-index: -2;
      top: -500px;
      left: -640px;
      width: 827px;
      height: 639px;
      background: url(../img/picture6-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture6 .picture__part--1 {
      top: 20px;
      left: -30px; }
    .picture__main--picture6 .picture__part--2 {
      top: 42px;
      left: 224px; }
    .picture__main--picture6 .picture__part--3 {
      top: 167px;
      left: -20px; }
    .picture__main--picture6 .picture__part--4 {
      top: -41px;
      left: 168px; }
    .picture__main--picture6 .picture__part--5 {
      top: 146px;
      left: 167px;
      transform: translate(30px, 30px); }
  .picture__main--picture7 {
    margin-top: 388px; }
    .picture__main--picture7 .picture__part {
      top: -16px;
      left: -31px; }
      .picture__main--picture7 .picture__part svg path:nth-child(1) {
        animation: lights 2s steps(1) infinite;
        animation-delay: 1s; }
      .picture__main--picture7 .picture__part svg path:nth-child(2) {
        opacity: 0;
        animation: lights 2s steps(1) infinite; }
      .picture__main--picture7 .picture__part svg path:nth-child(3) {
        opacity: 0;
        animation: lights 2s steps(1) infinite; }
      .picture__main--picture7 .picture__part svg path:nth-child(4) {
        animation: lights 2s steps(1) infinite;
        animation-delay: 1s; }
      .picture__main--picture7 .picture__part svg path:nth-child(5) {
        animation: lights 2s steps(1) infinite;
        animation-delay: 1s; }

@keyframes lights {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; } }
  .picture__main--picture8 {
    margin-top: 104px; }
    .picture__main--picture8:before {
      top: -470px;
      left: -480px;
      width: 627px;
      height: 654px;
      background: url(../img/picture7-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture8 > svg {
      position: relative;
      z-index: 1;
      animation: superman 1.5s infinite ease-in-out; }
    .picture__main--picture8 .picture__main-bg {
      position: absolute;
      top: -17px;
      left: -60px; }
    .picture__main--picture8 .picture__part {
      top: 85px;
      left: -42px;
      animation: clouds 1s infinite ease-out; }

@keyframes superman {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(8px); }
  100% {
    transform: translateY(0); } }

@keyframes clouds {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(3px); }
  100% {
    transform: translateY(0); } }
  .picture__main--picture9 {
    margin-top: 53px; }
    .picture__main--picture9:before {
      top: -150px;
      left: 150px;
      width: 574px;
      height: 214px;
      background: url(../img/picture8-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture9 .picture__part {
      top: -146px;
      left: -10px; }
  .picture__main--picture10 {
    margin-top: 197px; }
    .picture__main--picture10:before {
      top: -30px;
      left: -270px;
      width: 384px;
      height: 168px;
      background: url(../img/picture9-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture10 .picture__part--1 {
      top: -44px;
      left: 206px;
      animation: flag 4s infinite; }
    .picture__main--picture10 .picture__part--2 {
      top: 148px;
      left: 97px;
      animation: leg 2s infinite; }
    .picture__main--picture10 .picture__part--3 {
      top: -65px;
      left: 20px;
      animation: clouds 3s infinite ease-out; }

@keyframes leg {
  0% {
    transform: rotate(0);
    transform-origin: top center; }
  50% {
    transform: rotate(10deg);
    transform-origin: top center; }
  100% {
    transform: rotate(0);
    transform-origin: top center; } }

@keyframes flag {
  0% {
    transform: rotate(0);
    transform-origin: left bottom; }
  50% {
    transform: rotate(5deg);
    transform-origin: left bottom; }
  100% {
    transform: rotate(0);
    transform-origin: left bottom; } }
  .picture__main--picture11 {
    margin-top: 15px; }
    .picture__main--picture11:before {
      top: -280px;
      left: 190px;
      width: 586px;
      height: 391px;
      background: url(../img/picture10-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture11 .picture__part {
      top: 261px;
      left: 256px;
      opacity: 0; }
  .picture__main--picture12 {
    margin-top: 90px; }
    .picture__main--picture12:before {
      top: -220px;
      left: -330px;
      width: 508px;
      height: 307px;
      background: url(../img/picture11-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture12 .picture__part--1 {
      top: 20px;
      left: 80px;
      animation: jaw 3s infinite; }
    .picture__main--picture12 .picture__part--2 {
      top: -45px;
      left: -3px;
      opacity: 0;
      animation: blood 3s infinite; }

@keyframes jaw {
  0% {
    transform: rotate(0) translateY(0);
    transform-origin: 70% 80%; }
  50% {
    transform: rotate(5deg) translateY(-5px);
    transform-origin: 70% 80%; }
  100% {
    transform: rotate(0) translateY(0);
    transform-origin: 80% 80%; } }

@keyframes blood {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }
  .picture__main--picture13 {
    margin-top: 240px; }
    .picture__main--picture13:before {
      top: 10px;
      left: 200px;
      width: 461px;
      height: 73px;
      background: url(../img/picture12-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture13 .picture__part--1 {
      top: 13px;
      left: 30px; }
    .picture__main--picture13 .picture__part--2 {
      top: 13px;
      left: 74px; }
    .picture__main--picture13 .picture__part--3 {
      top: 14px;
      left: 123px; }
    .picture__main--picture13 .picture__part--4 {
      top: 10px;
      left: 150px; }
  .picture__main--picture14 {
    margin-top: 70px; }
    .picture__main--picture14:before {
      z-index: -2;
      top: -320px;
      left: -240px;
      width: 626px;
      height: 412px;
      background: url(../img/picture13-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture14 .picture__bg {
      position: absolute;
      z-index: -2;
      top: -46px;
      left: -5px;
      width: 636px;
      height: 629px;
      background: url(../img/picture14-bg.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture14 .picture__part--1 {
      top: 52px;
      left: 410px;
      animation: tabletLight 3s infinite linear; }
    .picture__main--picture14 .picture__part--2 {
      z-index: -1;
      top: 256px;
      left: 10px;
      animation: ship1 3s infinite linear;
      animation-delay: 2s; }
    .picture__main--picture14 .picture__part--3 {
      z-index: -1;
      top: 228px;
      left: 115px;
      animation: ship2 4s infinite linear; }

@keyframes tabletLight {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes ship1 {
  0% {
    transform: rotate(0);
    transform-origin: center bottom; }
  50% {
    transform: rotate(10deg);
    transform-origin: center bottom; }
  100% {
    transform: rotate(0);
    transform-origin: center bottom; } }

@keyframes ship2 {
  0% {
    transform: rotate(0);
    transform-origin: center bottom; }
  50% {
    transform: rotate(10deg);
    transform-origin: center bottom; }
  100% {
    transform: rotate(0);
    transform-origin: center bottom; } }
  .picture__main--picture15:before {
    z-index: -3;
    top: -90px;
    left: 140px;
    width: 556px;
    height: 188px;
    background: url(../img/picture14-arrow.svg) no-repeat;
    background-size: contain; }
  .picture__main--picture15 .picture__part {
    top: 46px;
    left: 42px; }
  .picture__main--picture16 {
    margin-top: 240px; }
    .picture__main--picture16:before {
      top: -90px;
      left: -400px;
      width: 497px;
      height: 152px;
      background: url(../img/picture15-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture16 .picture__part {
      z-index: -1;
      top: 0;
      left: 64px;
      opacity: 0; }
  .picture__main--picture17 {
    margin-top: 240px; }
    .picture__main--picture17:before {
      top: -190px;
      left: 180px;
      width: 530px;
      height: 304px;
      background: url(../img/picture16-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture17 .picture__part--1 {
      top: -17px;
      left: -55px;
      opacity: 0;
      animation: stars 1s steps(1) infinite; }
    .picture__main--picture17 .picture__part--2 {
      top: -42px;
      left: -26px;
      opacity: 0;
      animation: stars 1s steps(1) infinite;
      animation-delay: .5s; }

@keyframes stars {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; } }
  .picture__main--picture18 {
    margin-top: 157px; }
    .picture__main--picture18:before {
      top: -30px;
      left: -340px;
      width: 474px;
      height: 134px;
      background: url(../img/picture17-arrow.svg) no-repeat;
      background-size: contain; }
    .picture__main--picture18 .picture__part {
      top: 161px;
      left: 25px; }
  .picture__main--picture19:before {
    top: -270px;
    left: 250px;
    width: 535px;
    height: 328px;
    background: url(../img/picture18-arrow.svg) no-repeat;
    background-size: contain; }
  .picture__main--picture19 .picture__part {
    top: 107px;
    left: 98px;
    animation: shine 2s steps(1) infinite; }

@keyframes shine {
  0% {
    opacity: 0;
    transform: rotate(0deg); }
  25% {
    opacity: 1;
    transform: rotate(0deg); }
  50% {
    opacity: 0;
    transform: rotate(-7deg); }
  75% {
    opacity: 1;
    transform: rotate(-7deg); } }

.picture__part {
  position: absolute;
  transform: translate3d(0, 0, 0); }

.wrapper {
  overflow: hidden;
  width: 100%; }
  @media (max-width: 767.98px) {
    .wrapper {
      overflow: auto;
      height: 100vh; } }

.share {
  display: flex;
  align-items: center; }
  .share__link {
    display: flex;
    height: 36px;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    border-radius: 6px;
    transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); }
    .share__link:hover {
      opacity: .85;
      color: #fff; }
    .share__link:before {
      display: block;
      width: 37px;
      height: 36px;
      content: '';
      border-right: 1px solid #fff; }
    .share__link--vk {
      background-color: #4d76a1; }
      .share__link--vk:before {
        background: url(../img/vk--white.svg) no-repeat center;
        background-repeat: no-repeat; }
    .share__link--fb {
      margin-left: 8px;
      background-color: #24609e; }
      .share__link--fb:before {
        background: url(../img/fb--white.svg) no-repeat center;
        background-repeat: no-repeat; }
  .share__link-content {
    display: flex;
    width: 40px;
    height: 36px;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
    text-align: center; }
  .share__item--vk {
    transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); }
    .share__item--vk:hover {
      opacity: .8; }
    .share__item--vk td:nth-child(1) a {
      padding: 0 !important;
      display: flex !important;
      width: 37px;
      height: 36px;
      justify-content: center;
      align-items: center;
      background: #4d76a1 !important;
      border-right: 1px solid #fff !important;
      border-radius: 6px 0 0 6px !important; }
      .share__item--vk td:nth-child(1) a div {
        margin: 0 !important; }
    .share__item--vk td:nth-child(2) {
      display: none; }
    .share__item--vk td:nth-child(3) a {
      margin: 0 !important;
      display: flex !important;
      width: 40px;
      height: 36px;
      justify-content: center;
      align-items: center;
      background: #4d76a1;
      border-radius: 0 6px 6px 0 !important; }
      .share__item--vk td:nth-child(3) a div:nth-child(1) {
        display: none; }
      .share__item--vk td:nth-child(3) a div:nth-child(2) {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 14px !important;
        color: #fff !important;
        background: none !important;
        border: 0 !important; }
