/* Bootstrap */

.container {

  @include media-breakpoint-down(lg) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @include media-breakpoint-down(sm) {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.text-center {

  @include media-breakpoint-down(sm) {
    text-align: left !important;
  }
}
