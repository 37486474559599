.content {
  padding: 176px 0 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    padding: 63px 0;
  }

  @include media-breakpoint-down(sm) {
    margin-left: -12px;
    padding: 50px 12px;
    overflow: hidden;
    width: calc(100% + 24px);
    flex-direction: column;
  }

  &__more {
    margin-top: 60px;
    margin-left: auto;
    max-width: 100%;

    @include media-breakpoint-down(lg) {
      margin-top: 100px;
      max-width: 332px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 50px;
      max-width: 256px;
      order: 20;
    }
  }
}
