.item {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    right: auto !important;
    left: auto !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0 !important;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: flex-start;
  }

  &:nth-child(2n) {

    @include media-breakpoint-down(sm) {
      position: relative;
    }

    &:before {

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: -100px;
        left: -12px;
        display: block;
        width: 100vw;
        height: 300px;
        content: '';
        background: url(../img/arrow-mob--left.svg) no-repeat center;
        background-size: 100% auto;
      }
    }
  }

  &:nth-child(2n + 1) {

    @include media-breakpoint-down(sm) {
      position: relative;
    }

    &:before {

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: -100px;
        left: -12px;
        display: block;
        width: 100vw;
        height: 300px;
        content: '';
        background: url(../img/arrow-mob--right.svg) no-repeat center;
        background-size: 100% auto;
      }
    }
  }

  &__picture {

    @include media-breakpoint-down(sm) {
      margin-top: 0 !important;
      margin-right: auto !important;
      //      margin-left: auto !important;
    }
  }

  &__content {

    @include media-breakpoint-down(lg) {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0 !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: 100% !important;
      max-width: none !important;
    }
  }

  &--right {
    flex-direction: row-reverse;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &--left {
  }

  &--big {

    .item__content {
      padding-top: 12px;
      padding-left: 16px;

      @include media-breakpoint-down(lg) {
        padding-left: 0;
      }
    }
  }

  &--vertical {
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      align-self: flex-start;
    }

    .item__content {
      margin: 0 auto;
      max-width: 456px;

      @include media-breakpoint-down(lg) {
        max-width: 304px;
      }
    }

    h3 {
      margin-bottom: 12px;
      font-size: 28px;

      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
    }

    p {
      font-size: 16px;
    }
  }

  &--content-sm {

    .item__content {
      max-width: 360px;

      @include media-breakpoint-down(lg) {
        max-width: 304px;
      }
    }
  }

  &--1 {

    @include media-breakpoint-down(lg) {
      margin-left: -28px !important;
      width: calc(100% + 20px) !important;
      max-width: none;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0 !important;
      width: 100% !important;
      order: 1;
    }

    &:before {

      @include media-breakpoint-down(sm) {
        top: 0 !important;
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto !important;
      }
    }

    .picture__main {
      margin-left: -78px;
    }

    .item__content {

      @include media-breakpoint-down(lg) {
        padding-top: 0;
      }
    }
  }

  &--2 {

    @include media-breakpoint-down(sm) {
      order: 2;
    }

    .picture {
      margin-top: -43px;
      margin-right: -100px;

      @include media-breakpoint-down(lg) {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .item__content {
      margin-top: -20px;
      margin-right: 0;
    }
  }

  &--3 {
    margin-left: -75px;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      order: 3;
    }

    .item__content {
      margin-top: -20px;
      margin-right: 30px;
    }
  }

  &--4 {
    margin-top: -73px;
    margin-right: -32px;

    @include media-breakpoint-down(sm) {
      order: 4;
    }

    .item__content {
      margin-top: -20px;
      margin-right: 30px;
    }
  }

  &--5 {
    margin-left: -5px;

    @include media-breakpoint-down(sm) {
      order: 5;
    }

    .item__content {
      margin-top: -20px;
    }
  }

  &--6 {
    position: relative;
    left: -60px;
    margin-top: -9px;

    @include media-breakpoint-down(sm) {
      order: 6;
    }

    .item__content {
      margin-left: 70px;
    }
  }

  &--7 {
    margin-top: 10px;
    margin-left: 30px;

    @include media-breakpoint-down(sm) {
      padding-left: 40px;
      width: 100% !important;
    }

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto !important;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 7;
    }
  }

  &--8 {

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 8;
    }

    .item__content {
      margin-top: 70px;
      margin-left: -17px;
    }
  }

  &--9 {
    margin-top: 26px;
    margin-left: -20px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 9;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 150px !important;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 118px;
    }
  }

  &--10 {
    position: relative;
    left: -90px;
    margin-top: 95px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 10;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 100px;
    }
  }

  &--11 {
    margin-left: -80px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 11;
    }

    .item__content {
    }
  }

  &--12 {
    position: relative;
    left: -35px;

    @include media-breakpoint-down(sm) {
      order: 12;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 140px;
    }
  }

  &--13 {
    position: relative;
    left: -35px;

    @include media-breakpoint-down(sm) {
      order: 13;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 135px;
    }
  }

  &--14 {
    margin-top: -6px;
    margin-right: -300px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 14;
    }

    .item__content {
      margin-top: -15px;
      margin-left: -15px;
    }

    .picture {

      @include media-breakpoint-down(lg) {
        margin-right: -300px;
      }
    }
  }

  &--15 {
    position: relative;
    left: -52px;
    margin-top: 6px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 15;
    }

    .item__content {
      margin-top: -20px;
    }
  }

  &--16 {
    position: relative;
    left: -35px;
    margin-top: -3px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 16;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 100px !important;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 175px;
    }
  }

  &--17 {
    margin-top: -292px;
    margin-left: -72px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(lg) {
      margin-top: -150px;
    }

    @include media-breakpoint-down(sm) {
      order: 17;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 70px;
    }
  }

  &--18 {
    position: relative;
    top: -5px;
    left: -40px;

    &:before {

      @include media-breakpoint-down(sm) {
        background: url(../img/arrow-mob--right.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    @include media-breakpoint-down(sm) {
      order: 18;
    }

    .item__content {
      margin-top: -20px;
      margin-left: 50px;
    }
  }

  &--19 {
    margin-top: -20px;
    margin-left: -80px;

    @include media-breakpoint-down(sm) {
      order: 19;
    }

    &:before {

      @include media-breakpoint-down(sm) {
        right: -50% !important;
        left: auto !important;
        background: url(../img/arrow-mob--left.svg) no-repeat center !important;
        background-size: 100% auto;
      }
    }

    .picture {

      @include media-breakpoint-down(lg) {
        zoom: .6;
      }
    }

    .item__content {
      margin-top: 26px;
    }
  }
}
