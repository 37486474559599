.share {
  display: flex;
  align-items: center;

  &__link {
    display: flex;
    height: 36px;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    border-radius: 6px;

    @include transition;

    &:hover {
      opacity: .85;
      color: #fff;
    }

    &:before {
      display: block;
      width: 37px;
      height: 36px;
      content: '';
      border-right: 1px solid #fff;
    }

    &--vk {
      background-color: #4d76a1;

      &:before {
        background: url(../img/vk--white.svg) no-repeat center;
        background-repeat: no-repeat;
      }
    }

    &--fb {
      margin-left: 8px;
      background-color: #24609e;

      &:before {
        background: url(../img/fb--white.svg) no-repeat center;
        background-repeat: no-repeat;
      }
    }
  }

  &__link-content {
    display: flex;
    width: 40px;
    height: 36px;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }

  &__item {

    &--vk {

      @include transition;

      &:hover {
        opacity: .8;
      }

      td:nth-child(1) {

        a {
          padding: 0 !important;
          display: flex !important;
          width: 37px;
          height: 36px;
          justify-content: center;
          align-items: center;
          background: #4d76a1 !important;
          border-right: 1px solid #fff !important;
          border-radius: 6px 0 0 6px !important;

          div {
            margin: 0 !important;
          }
        }
      }

      td:nth-child(2) {
        display: none;
      }

      td:nth-child(3) {

        a {
          margin: 0 !important;
          display: flex !important;
          width: 40px;
          height: 36px;
          justify-content: center;
          align-items: center;
          background: #4d76a1;
          border-radius: 0 6px 6px 0 !important;

          div {

            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              margin: 0 !important;
              padding: 0 !important;
              font-size: 14px !important;
              color: #fff !important;
              background: none !important;
              border: 0 !important;
            }
          }
        }
      }
    }
  }
}
