.front {
  padding: 50px 0 120px;
  background: $primary;

  @include media-breakpoint-down(lg) {
    padding: 40px 0 120px;
  }

  @include media-breakpoint-down(sm) {
    padding: 36px 0 80px;
  }

  &__logo {
    margin-bottom: 50px;
    display: block;

    @include media-breakpoint-down(lg) {
      margin-bottom: 48px;
      max-width: 200px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
      max-width: 176px;
    }
  }

  h1 {

    span {
      display: inline-block;
    }
  }

  &__dscr {
    margin-top: 20px;
    margin-bottom: 80px;
    max-width: 90%;
    font-size: 36px;
    line-height: 1.33;
    color: rgba(#fff, .7);

    @include media-breakpoint-down(lg) {
      margin-top: 15px;
      margin-bottom: 58px;
      font-size: 28px;
      line-height: 1.3;
    }

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }
}
