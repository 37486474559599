/* Custom variables */

$spacer: 10px;

$spacers: (
  0: 0,
  5: (
    $spacer * .5,
  ),
  10: $spacer,
  15: (
    $spacer * 1.5,
  ),
  20: (
    $spacer * 2,
  ),
  25: (
    $spacer * 2.5,
  ),
  30: (
    $spacer * 3,
  ),
  35: (
    $spacer * 3.5,
  ),
  40: (
    $spacer * 4,
  ),
  45: (
    $spacer * 4.5,
  ),
  50: (
    $spacer * 5,
  ),
  55: (
    $spacer * 5.5,
  ),
  60: (
    $spacer * 6,
  ),
  65: (
    $spacer * 6.5,
  ),
  70: (
    $spacer * 7,
  ),
  75: (
    $spacer * 7.5,
  ),
  80: (
    $spacer * 8,
  ),
  85: (
    $spacer * 8.5,
  ),
  90: (
    $spacer * 9,
  ),
  95: (
    $spacer * 9.5,
  ),
  100: (
    $spacer * 10,
  ),
);

$link-hover-decoration: none;

$line-height-sm: 1;

$line-height-lg: 2;

$transition-base: .3s cubic-bezier(.25, .1, .25, 1);

$headings-margin-bottom: $spacer;

/* Project variables */
$font-family-sans-serif: 'ALS Hauss', 'Arial', sans-serif;

$primary: #2572f9;
$black: #1e1e2f;
$dark: #3f3f55;

$grid-gutter-width: 24px;

$container-max-widths: (
  xl: 1152px,
);

/* Imports */
@import '../../node_modules/bootstrap/scss/functions';

@import '../../node_modules/bootstrap/scss/variables';

@import '../../node_modules/bootstrap/scss/mixins';

@import '../../node_modules/bootstrap/scss/root';

@import '../../node_modules/bootstrap/scss/reboot';

@import '../../node_modules/bootstrap/scss/type';

@import '../../node_modules/bootstrap/scss/images';

@import '../../node_modules/bootstrap/scss/grid';

@import '../../node_modules/bootstrap/scss/forms';

@import '../../node_modules/bootstrap/scss/buttons';

@import '../../node_modules/bootstrap/scss/transitions';

@import '../../node_modules/bootstrap/scss/custom-forms';

@import '../../node_modules/bootstrap/scss/utilities';

@import '../../node_modules/bootstrap/scss/print';
