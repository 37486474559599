.socials {
  display: flex;

  &__item {
    width: 36px;
    height: 36px;
    background-color: #f0f5fa;
    border-radius: 50%;

    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
    }

    @include transition;

    &:hover {
      opacity: .8;
    }

    &--fb {
      background-image: url(../img/fb.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &--tg {
      background-image: url(../img/tg.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &--vk {
      background-image: url(../img/vk.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &--tw {
      background-image: url(../img/tw.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    &--ok {
      background-image: url(../img/ok.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    & + & {
      margin-left: 8px;
    }
  }

  &--connect {

    .socials__item {
      width: 45px;
      height: 45px;
      background: #fff;

      @include media-breakpoint-down(lg) {
        width: 43px;
        height: 43px;
      }

      & + .socials__item {
        margin-left: 13px;
      }

      &--fb {
        background-image: url(../img/fb--blue.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      &--tg {
        background-image: url(../img/tg--blue.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      &--vk {
        background-image: url(../img/vk--blue.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
